import { View, StyleSheet } from "react-native";
import CustomText from "../../../components/common/general/CustomText/CustomText";
import React, { useEffect, useState } from "react";
import { UserCirclePlus } from "../../../components/svgs/common";
import UsersTable, { UsersTableProps } from "./UsersTable";
import {
  GetFacultyByNameOrEmailDocument,
  useAssignCourseToFacultyMutation,
  useGetFacultyByNameOrEmailQuery,
  useInviteUserToSchoolMutation,
  useRemoveCourseAssignmentFromFacultyMutation,
  useRevokeFacultyMutation,
  useSchoolsQuery,
} from "../../../graphql/generated/graphql";

import NewCustomAxioActionModal from "./NewCustomAxioActionModal";
import AssignCourseToFacultyListContainer from "./AssignCourseToFacultyListContainer";
import RemoveCourseFromFacultyListContainer from "./RemoveCourseFromFacultyList";
import InviteFacultyModalContainer from "./InviteFacultyModalContainer";
import { NewCustomActionButton } from "../../../components/common/general/CustomButton/NewCustomActionButton";
import Pagination from "../../../components/common/Pagination/Pagination";
import CustomSearchInput from "../../../components/common/general/CustomSearchInput/CustomSearchInput";

const modals = {
  revokeFaculty: {
    title: "Remove Teacher from Platform?",
    ariaLabel: "Remove Teacher Modal",
    warningText:
      "Following faculty will no longer have access to the platform. This action cannot be undone, and all associated data will be deleted.",
    actionButtonText: "Remove Teacher",
  },
  revokeConfirmation: {
    title: "Success!",
    ariaLabel: "Revoke Confirmation Modal",
    warningText: "The faculty has been successfully revoked.",
    actionButtonText: "OK",
  },
};

export enum UsersTabModalState {
  CLOSED = "CLOSED",
  REVOKE = "REVOKE",
  REVOKE_CONFIRMATION = "REVOKE_CONFIRMATION",
  ASSIGN_COURSE = "ASSIGN_COURSE",
  REMOVE_COURSE = "REMOVE_COURSE",
  INVITE = "INVITE",
}

const UsersTabContainer = ({ customColor }) => {
  const [searchQuery, setSearchQuery] = useState("");
  const [page, setPage] = useState(1);
  const [pageSize, setPageSize] = useState(5);
  const [modalState, setModalState] = useState({
    state: UsersTabModalState.CLOSED,
    facultyId: "",
    facultyName: "",
  });

  const { data: schoolsData, loading: schoolsLoading } = useSchoolsQuery();

  const {
    data: facultyData,
    loading: facultyLoading,
    error: facultyError,
    refetch: facultyRefetch,
  } = useGetFacultyByNameOrEmailQuery({
    variables: {
      nameOrEmail: searchQuery,
      schoolId: schoolsData?.getSchools[0].id,
      page: page,
      pageSize,
    },
  });

  const refetchQueries = [
    {
      query: GetFacultyByNameOrEmailDocument,
      variables: {
        nameOrEmail: searchQuery,
        schoolId: schoolsData?.getSchools[0].id,
        page: page,
        pageSize: pageSize,
      },
    },
  ];

  const [assignCourseToFaculty] = useAssignCourseToFacultyMutation();
  const [removeCourseFromFaculty] = useRemoveCourseAssignmentFromFacultyMutation();
  const [inviteUserToSchool] = useInviteUserToSchoolMutation();
  const [revokeFaculty] = useRevokeFacultyMutation();

  const inviteMember = async ({
    email,
    name,
    role,
  }: {
    email: string;
    name: string;
    role: "School Admin" | "Faculty";
  }) => {
    try {
      const result = await inviteUserToSchool({
        variables: {
          input: {
            email: email,
            name: name,
            role: role,
          },
        },
        refetchQueries: refetchQueries,
      });

      return result.data?.inviteUserToSchool?.success;
    } catch (error) {
      return false;
    }
  };

  const assignCourse = async (courseId: string) => {
    await assignCourseToFaculty({
      variables: {
        userId: modalState.facultyId,
        courseId: courseId,
      },
      refetchQueries: refetchQueries,
    });
    setModalState({ state: UsersTabModalState.CLOSED, facultyId: "", facultyName: "" });
  };

  const removeCourse = async (courseId: string) => {
    await removeCourseFromFaculty({
      variables: {
        userId: modalState.facultyId,
        courseId: courseId,
      },
      refetchQueries: refetchQueries,
    });
    setModalState({ state: UsersTabModalState.CLOSED, facultyId: "", facultyName: "" });
  };

  const handleRevokeFaculty = async () => {
    await revokeFaculty({
      variables: {
        userId: modalState.facultyId,
      },
      refetchQueries: refetchQueries,
    });
    setModalState({ state: UsersTabModalState.REVOKE_CONFIRMATION, facultyId: "", facultyName: "" });
    setSearchQuery("");
  };

  const handleActionModalOpen = async (facultyId: string, facultyName: string, modalType: UsersTabModalState) => {
    setModalState({ state: modalType, facultyId, facultyName });
  };

  const handleModalClose = () => {
    setModalState({ state: UsersTabModalState.CLOSED, facultyId: "", facultyName: "" });
  };

  const handlePageChange = (newPage: number) => {
    if (newPage >= 1 && newPage <= facultyData?.getFacultyByNameOrEmail?.data.totalCount) {
      setPage(newPage);
    }
  };

  useEffect(() => {
    setPage(1);
  }, [searchQuery]);

  useEffect(() => {
    facultyRefetch();
  }, [searchQuery, page, pageSize]);

  return (
    <>
      <View style={styles.container}>
        <View style={styles.header}>
          <View style={styles.headerRow}>
            <CustomText text="Manage Organization" size="l" weight="bold" style={styles.headerText} />
            <NewCustomActionButton
              text="Invite members"
              onPress={() => setModalState({ state: UsersTabModalState.INVITE, facultyId: "", facultyName: "" })}
              leftIcon={<UserCirclePlus fill={customColor} />}
            />
          </View>
          <View style={styles.headerRow}>
            <View style={styles.subHeaderContainer}>
              <CustomText text="Organization members" size="m" weight="semiBold" style={styles.subHeaderText} />
              <CustomText
                text={`(${facultyData?.getFacultyByNameOrEmail?.count})`}
                size="m"
                weight="semiBold"
                style={styles.subHeaderText}
                useTranslationText={false}
              />
            </View>
            <CustomSearchInput searchQuery={searchQuery} setSearchQuery={setSearchQuery} />
          </View>
        </View>
        <UsersTable
          faculties={
            (facultyData?.getFacultyByNameOrEmail?.data.faculties || []) as unknown as UsersTableProps["faculties"]
          }
          onActionModalOpen={handleActionModalOpen}
        />
        <Pagination
          page={page}
          handlePageChange={handlePageChange}
          totalPageCount={Math.ceil(facultyData?.getFacultyByNameOrEmail?.data.totalCount / pageSize)}
        />
      </View>
      <NewCustomAxioActionModal
        visible={modalState.state === UsersTabModalState.REVOKE}
        onClose={handleModalClose}
        onAction={handleRevokeFaculty}
        modalTexts={modals.revokeFaculty}
        contextualText={modalState.facultyName}
      />
      <NewCustomAxioActionModal
        visible={modalState.state === UsersTabModalState.REVOKE_CONFIRMATION}
        onClose={handleModalClose}
        modalTexts={modals.revokeConfirmation}
        contextualText={modalState.facultyName}
      />
      <AssignCourseToFacultyListContainer
        visible={modalState.state === UsersTabModalState.ASSIGN_COURSE}
        onClose={handleModalClose}
        onAction={assignCourse}
      />
      <RemoveCourseFromFacultyListContainer
        visible={modalState.state === UsersTabModalState.REMOVE_COURSE}
        onClose={handleModalClose}
        onAction={removeCourse}
        userId={modalState.facultyId}
      />
      <InviteFacultyModalContainer
        visible={modalState.state === UsersTabModalState.INVITE}
        onClose={handleModalClose}
        onSubmit={inviteMember}
      />
    </>
  );
};

const styles = StyleSheet.create({
  container: {
    padding: 24,
    backgroundColor: "#fff",
    borderRadius: 8,
    flex: 1,
  },
  header: {
    flexDirection: "column",
    width: "100%",
    marginBottom: 24,
    gap: 24,
  },
  headerRow: {
    flexDirection: "row",
    width: "100%",
    alignItems: "center",
    justifyContent: "space-between",
    paddingVertical: 16,
  },
  subHeaderContainer: {
    flexDirection: "row",
    alignItems: "center",
    gap: 8,
  },
  headerText: {
    color: "#000",
  },
  subHeaderText: {
    color: "#000",
  },
});

export default UsersTabContainer;
