import React from "react";
import { DataTable } from "react-native-paper";
import CustomText from "../../../components/common/general/CustomText/CustomText";
import { FlatList, StyleSheet, View } from "react-native";
import { Checkbox } from "react-native-paper";

export interface CourseRosterTableProps {
  students: {
    id: string;
    name: string;
    email: string;
    pendingCourseEnrollmentInvite: boolean;
    enrollmentDate: string;
  }[];
  setSelectedStudents: (studentIds: string[]) => void;
  selectedStudents: string[];
}

const CourseRosterTable = ({ students, setSelectedStudents, selectedStudents }: CourseRosterTableProps) => {
  const toggleSelection = (id: string) => {
    const newSelectedIds = new Set(selectedStudents);
    if (newSelectedIds.has(id)) {
      newSelectedIds.delete(id);
    } else {
      newSelectedIds.add(id);
    }
    setSelectedStudents(Array.from(newSelectedIds));
  };

  const toggleSelectAll = () => {
    if (selectedStudents.length === students.length) {
      setSelectedStudents([]);
    } else {
      setSelectedStudents(students.map((s) => s.id));
    }
  };

  return (
    <>
      <DataTable>
        <DataTable.Header style={styles.tableHeader}>
          <DataTable.Title style={styles.checkboxColumn} id="checkboxColumn">
            <Checkbox
              status={
                selectedStudents.length === students.length && students.length > 0
                  ? "checked"
                  : selectedStudents.length > 0
                    ? "indeterminate"
                    : "unchecked"
              }
              onPress={toggleSelectAll}
              theme={{ colors: { primary: "#3e68fe" } }}
            />
          </DataTable.Title>
          <DataTable.Title style={styles.nameColumn}>
            <CustomText text="Student Name" size="s" weight="medium" style={{}} />
          </DataTable.Title>
          <DataTable.Title style={styles.emailColumn}>
            <CustomText text="Email" size="s" weight="medium" style={{}} />
          </DataTable.Title>
          <DataTable.Title style={styles.statusColumn}>
            <CustomText text="Status" size="s" weight="medium" style={{}} />
          </DataTable.Title>
          <DataTable.Title style={styles.dateColumn}>
            <CustomText text="Date Enrolled" size="s" weight="medium" style={{}} />
          </DataTable.Title>
        </DataTable.Header>

        <FlatList
          data={students}
          renderItem={({ item: student }) => (
            <DataTable.Row style={styles.tableRow}>
              <DataTable.Cell style={styles.checkboxColumn}>
                <Checkbox
                  status={selectedStudents.includes(student.id) ? "checked" : "unchecked"}
                  onPress={() => toggleSelection(student.id)}
                  theme={{ colors: { primary: "#3e68fe" } }}
                />
              </DataTable.Cell>
              <DataTable.Cell style={styles.nameColumn}>
                <CustomText text={student.name} size="s" style={styles.studentName} useTranslationText={false} />
              </DataTable.Cell>
              <DataTable.Cell style={styles.emailColumn}>
                <CustomText text={student.email} size="s" style={styles.emailText} useTranslationText={false} />
              </DataTable.Cell>
              <DataTable.Cell style={styles.statusColumn}>
                <CustomText
                  text={student.pendingCourseEnrollmentInvite ? "Invited" : "Enrolled"}
                  size="s"
                  style={[
                    styles.statusText,
                    student.pendingCourseEnrollmentInvite ? styles.invitedText : styles.enrolledText,
                  ]}
                  useTranslationText={false}
                />
              </DataTable.Cell>
              <DataTable.Cell style={styles.dateColumn}>
                <CustomText
                  text={new Date(student.enrollmentDate).toLocaleDateString()}
                  size="s"
                  style={styles.dateText}
                  useTranslationText={false}
                />
              </DataTable.Cell>
            </DataTable.Row>
          )}
          keyExtractor={(item) => item.id}
        />
      </DataTable>
    </>
  );
};

const styles = StyleSheet.create({
  tableHeader: {
    minHeight: 48,
    height: 48,
  },
  checkboxColumn: {
    flex: 1,
    paddingHorizontal: 16,
    justifyContent: "center",
    alignItems: "center",
    minHeight: 48,
  },
  nameColumn: { flex: 4, minHeight: 48 },
  emailColumn: { flex: 4, minHeight: 48 },
  statusColumn: { flex: 2, minHeight: 48 },
  dateColumn: { flex: 3, minHeight: 48 },
  tableRow: {
    display: "flex",
    flexDirection: "row",
    position: "relative",
    width: "100%",
    paddingVertical: 16,
    alignItems: "center",
    minHeight: 48,
  },
  studentName: {
    color: "#111827",
    fontSize: 14,
    fontWeight: "500",
  },
  emailText: {
    color: "#374151",
    fontSize: 14,
  },
  statusText: {
    fontSize: 14,
    fontWeight: "500",
  },
  invitedText: {
    color: "#F59E0B",
  },
  enrolledText: {
    color: "#10B981",
  },
  dateText: {
    color: "#6B7280",
    fontSize: 14,
  },
});

export default CourseRosterTable;
