import Markdown, { MarkdownIt } from "react-native-markdown-display";
import { StyleSheet, View } from "react-native";
import CopyCodeButton from "../../../chat/CopyCodeButton/CopyCodeButton.js";
import SyntaxHighlighterContainer from "../CustomSyntaxHighlighter/CustomSyntaxHighlighter.js";
import math from "markdown-it-texmath";
import { MathJax } from "better-react-mathjax";
import katex from "katex";

const preprocessContent = (content) => {
  const lines = content.split("\n");
  const processedLines = [];

  for (let i = 0; i < lines.length; i++) {
    let line = lines[i];

    if (line.trim().startsWith("\\[") && (i === 0 || lines[i - 1].trim() !== "")) {
      processedLines.push("");
    }
    processedLines.push(line);
    if (line.trim().endsWith("\\]") && (i === lines.length - 1 || lines[i + 1].trim() !== "")) {
      processedLines.push("");
    }
  }

  return processedLines.join("\n");
};

function CustomMarkdown({ content }) {
  const markdownItInstance = new MarkdownIt({
    html: true,
    linkify: true,
    typographer: true,
  }).use(math, {
    engine: katex,
    delimiters: "brackets",
    katexOptions: { macros: { "\\RR": "\\mathbb{R}" } },
  });
  content = preprocessContent(content); //note: for platforms other than web we may need to use react-native-mathjax which is based on WebView

  return (
    <Markdown rules={markdownRules} style={styles} markdownit={markdownItInstance}>
      {content}
    </Markdown>
  );
}

const markdownRules = {
  math_inline: (node) => {
    return (
      <View role="math" key={node.key}>
        {/* eslint-disable react-native/no-raw-text */}
        <MathJax inline>{`\\(${node.content}\\)`}</MathJax>
        {/* eslint-disable react-native/no-raw-text */}
      </View>
    );
  },
  math_block: (node) => {
    return (
      <View role="math" key={node.key}>
        {/* eslint-disable react-native/no-raw-text */}
        <MathJax hideUntilTypeset={"first"}>{`\\[${node.content}\\]`}</MathJax>
        {/* eslint-disable react-native/no-raw-text */}
      </View>
    );
  },
  code_block: (node) => {
    // we trim new lines off the end of code blocks because the parser sends an extra one.
    let { content } = node;
    if (typeof node.content === "string" && node.content.charAt(node.content.length - 1) === "\n") {
      content = node.content.substring(0, node.content.length - 1);
    }
    const languageType = node.sourceInfo;

    return (
      <View key={node.key}>
        <CopyCodeButton {...{ content, languageType }} />
        <SyntaxHighlighterContainer {...{ content, language: languageType }} />
      </View>
    );
  },
  fence: (node) => {
    // we trim new lines off the end of code blocks because the parser sends an extra one.
    let { content } = node;
    if (typeof node.content === "string" && node.content.charAt(node.content.length - 1) === "\n") {
      content = node.content.substring(0, node.content.length - 1);
    }
    const languageType = node.sourceInfo;

    return (
      <View key={node.key}>
        <CopyCodeButton {...{ content, languageType }} />
        <SyntaxHighlighterContainer {...{ content, language: languageType }} />
      </View>
    );
  },
};

/* eslint-disable react-native/no-unused-styles */
const styles = StyleSheet.create({
  // The main container
  body: {
    fontFamily: "Inter",
    color: "#344054",
    fontSize: 16,
    fontWeight: "400",
    lineHeight: 24,
    wordWrap: "break-word",
    fontStyle: "normal",
  },
  // Headings
  heading1: {
    fontSize: 24,
    flexDirection: "row",
    color: "#344054",
    fontFamily: "Inter",
    lineHeight: 34,
    wordWrap: "break-word",
    fontStyle: "normal",
    fontWeight: 400,
    marginBottom: 10,
  },
  heading2: {
    fontSize: 20,
    flexDirection: "row",
    color: "#344054",
    fontFamily: "Inter",
    fontStyle: "normal",
    fontWeight: "700",
    lineHeight: 30,
    wordWrap: "break-word",
    marginTop: 5,
    marginBottom: 10,
  },
  heading3: {
    fontWeight: "700",
    flexDirection: "row",
    fontSize: 18,
    fontFamily: "Inter",
  },
  heading4: {
    flexDirection: "row",
    fontSize: 16,
    fontFamily: "Inter",
  },
  heading5: {
    flexDirection: "row",
    fontSize: 13,
    fontFamily: "Inter",
  },
  heading6: {
    flexDirection: "row",
    fontSize: 11,
    fontFamily: "Inter",
  },

  // Horizontal Rule
  hr: {
    backgroundColor: "#D3D3D3",
    height: 1,
    marginTop: 10,
    marginBottom: 10,
    width: "100%",
    borderStyle: "dashed",
    borderWidth: 1,
  },

  // Emphasis
  strong: {
    fontWeight: "700",
    fontFamily: "Inter",
  },
  em: {
    fontStyle: "italic",
  },
  s: {
    textDecorationLine: "line-through",
  },

  // Blockquotes
  blockquote: {
    fontStyle: "italic",
    fontSize: 16,
    lineHeight: 24,
    backgroundColor: "#F5F5F5",
    borderColor: "#CCC",
    borderLeftWidth: 4,
    marginLeft: 5,
    paddingHorizontal: 5,
  },

  // Lists
  bullet_list: {
    fontSize: 16,
    marginBottom: 8, // Increased from 6
    marginTop: 8, // Increased from 6
  },
  ordered_list: {
    fontSize: 16,
    marginBottom: 8, // Increased from 6
    marginTop: 8, // Increased from 6
  },
  list_item: {
    flexDirection: "row",
    justifyContent: "flex-start",
    marginBottom: 6, // Added spacing between list items
  },
  bullet_list_icon: {
    marginLeft: 10,
    marginRight: 10,
  },
  bullet_list_content: {
    flex: 1,
  },
  ordered_list_icon: {
    marginRight: 5,
    fontSize: 16,
  },
  ordered_list_content: {
    flex: 1,
    fontSize: 16,
  },
  list_level_2: {
    marginLeft: 40,
  },

  // Code
  code_inline: {
    borderWidth: 1,
    borderColor: "#CCCCCC",
    backgroundColor: "#f5f5f5",
    padding: 3,
    borderRadius: 4,
    fontSize: 16,
    fontFamily: "Courier",
  },
  // Tables
  table: {
    borderWidth: 1,
    borderColor: "#000000",
    borderRadius: 3,
  },
  thead: {},
  tbody: {},
  th: {
    flex: 1,
    padding: 5,
  },
  tr: {
    borderBottomWidth: 1,
    borderColor: "#000000",
    flexDirection: "row",
  },
  td: {
    flex: 1,
    padding: 5,
  },

  // Links
  link: {
    textDecorationLine: "underline",
  },
  blocklink: {
    flex: 1,
    borderColor: "#000000",
    borderBottomWidth: 1,
  },

  // Images
  image: {
    flex: 1,
  },

  // Text Output
  text: {
    fontFamily: "Inter",
  },
  textgroup: {},
  paragraph: {
    marginTop: 10,
    marginBottom: 15,
    flexWrap: "wrap",
    flexDirection: "row",
    alignItems: "flex-start",
    justifyContent: "flex-start",
    width: "100%",
  },
  hardbreak: {
    width: "100%",
    height: 1,
  },
  softbreak: {},

  math_inline: {
    display: "block",
  },
  math_block: {
    display: "block",
  },
  // Believe these are never used but retained for completeness
  pre: {},
  inline: {},
  span: {},
});
/* eslint-enable react-native/no-unused-styles */

export default CustomMarkdown;
