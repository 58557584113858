import React, { useState, useEffect } from "react";
import { View, StyleSheet } from "react-native";
import CustomModal from "../../../components/common/general/CustomModal/CustomModal";
import EditActivity from "../../../components/customCoursework/EditActivity/EditActivity";
import ChooseActivity from "../../../components/customCoursework/ChooseActivity/ChooseActivity";

const EditActivityModalContainer = ({ visible, onClose, onSave, error, existingActivity = null }) => {
  const [step, setStep] = useState(existingActivity ? "edit" : "choose");
  const [selectedActivityType, setSelectedActivityType] = useState(
    existingActivity ? "Interactive Learning Experience" : null
  );

  // Format the activity data to match what EditActivity expects
  const formattedActivityData = existingActivity
    ? {
        title: existingActivity.title,
        description: existingActivity.description,
        // Map only the objective strings from the objectives array
        objectives:
          existingActivity.objectives
            ?.filter((obj) => obj.objective) // Filter out empty objectives
            ?.map((obj) => obj.objective) || [],
        id: existingActivity.id,
        type: "Interactive Learning Experience",
      }
    : null;

  useEffect(() => {
    if (!visible) {
      setStep(existingActivity ? "edit" : "choose");
      setSelectedActivityType(existingActivity ? "Interactive Learning Experience" : null);
    }
  }, [visible, existingActivity]);

  const handleActivityTypeSelect = (type) => {
    setSelectedActivityType(type);
    setStep("edit");
  };

  const handleSave = (activityData) => {
    onSave({
      ...activityData,
      type: selectedActivityType,
      id: existingActivity?.id,
    });
  };

  const handleClose = () => {
    onClose();
    setStep(existingActivity ? "edit" : "choose");
    setSelectedActivityType(existingActivity ? "Interactive Learning Experience" : null);
  };

  return (
    <CustomModal style={{ padding: -20, maxHeight: 800 }} visible={visible} onClose={handleClose}>
      <View style={styles.container}>
        {!existingActivity && step === "choose" ? (
          <ChooseActivity onSelect={handleActivityTypeSelect} onCancel={handleClose} />
        ) : (
          <EditActivity
            // activityType="Interactive Learning Experience"
            activityType={selectedActivityType}
            onSave={handleSave}
            onCancel={handleClose}
            isNewActivity={!existingActivity}
            error={error}
            activityData={formattedActivityData}
          />
        )}
      </View>
    </CustomModal>
  );
};

const styles = StyleSheet.create({
  container: {
    flex: 1,
    backgroundColor: "#fff",
    borderRadius: 8,
  },
});

export default EditActivityModalContainer;
