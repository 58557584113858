import { useEffect, useState } from "react";
import { usePostHog } from "posthog-js/react";
import { View, StyleSheet } from "react-native";
import CustomText from "../components/common/general/CustomText/CustomText";
import CustomSpacing from "../components/common/layout/CustomSpacing/CustomSpacing";
import { useRoute } from "@react-navigation/native";
import useResponsiveScreen from "../hooks/useResponsiveScreen";
import { MotiView } from "moti";
import ClassCardProgressListContainer from "../containers/school/ClassCardProgressListContainer/ClassCardProgressListContainer";
import TabNavigator from "../components/common/general/TabNavigator/TabNavigator";
import NoChatBarLayout from "../layouts/NoChatBarLayout.web";
import { useAppConfig } from "../AppConfigProvider";

function CustomSchoolStudentView() {
  const posthog = usePostHog();
  const { isMedium } = useResponsiveScreen();
  const route = useRoute();
  const appConfig = useAppConfig();
  const primaryColor = appConfig.primaryColor;

  const params = route.params;
  const [activeTab, setActiveTab] = useState(params?.tab || "all");
  const integrationId = appConfig.axioSchools[0];

  const tabs = [
    {
      key: "all",
      title: "All",
    },
    {
      key: "general",
      title: "General",
    },
    {
      key: "core",
      title: "Core",
    },
    {
      key: "electives",
      title: "Electives",
    },
  ];

  useEffect(() => {
    posthog?.capture("School Page Visited");
  }, []);

  return (
    <NoChatBarLayout>
      <MotiView
        from={{
          opacity: 0,
          translateY: 20,
          scale: 1,
        }}
        animate={{
          opacity: 1,
          translateY: 0,
          scale: 1,
        }}
        delay={10}
        transition={{ type: "timing", duration: 500 }}>
        <View style={{ flexDirection: "column", justifyContent: "flex-start" }}>
          <View style={{ flexDirection: "row", justifyContent: "space-between", alignItems: "flex-start" }}>
            <CustomText
              useTranslationText={false}
              text={"Courses"}
              size={isMedium ? "l" : "xs"}
              textType="display"
              weight="ultraBold"
              role="heading"
              aria-level="1"
            />
          </View>
          <CustomText
            useTranslationText={false}
            text="Explore the courses needed to complete your program."
            size="m"
            style={styles.coursesDescription}
          />
        </View>
      </MotiView>
      <CustomSpacing type="vertical" size="l" />
      <MotiView
        from={{
          opacity: 0,
          translateY: 20,
          scale: 1,
        }}
        animate={{
          opacity: 1,
          translateY: 0,
          scale: 1,
        }}
        delay={800}
        transition={{ type: "timing", duration: 600 }}>
        <ClassCardProgressListContainer integrationId={integrationId} activeTab={activeTab} />
      </MotiView>
      <MotiView
        from={{
          opacity: 0,
          translateY: 20,
          scale: 1,
        }}
        animate={{
          opacity: 1,
          translateY: 0,
          scale: 1,
        }}
        delay={1300}
        transition={{ type: "timing", duration: 600 }}>
        <CustomSpacing type="vertical" size="l" />
      </MotiView>
    </NoChatBarLayout>
  );
}

const styles = StyleSheet.create({
  coursesDescription: {
    color: "#667085",
    marginTop: 8,
    fontSize: 16,
    lineHeight: 24,
    fontFamily: "Inter",
    fontWeight: 300,
    marginHorizontal: 4,
  },
});

export default CustomSchoolStudentView;
