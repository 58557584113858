import DegreeCardList from "../../components/degree/DegreeCardList";
import { MotiView } from "moti";

//dummy data remove later
const degrees = [
  {
    id: "1",
    title: "Computer Science",
    description:
      "A comprehensive program that covers the fundamentals of computer science, including algorithms, data structures, software engineering, and computer systems. The program prepares students for careers in software development, data science, and technology innovation. With hands-on experience in coding, problem-solving, and real-world projects, graduates are well-equipped to tackle complex challenges in the ever-evolving tech industry. The degree also provides a strong foundation for pursuing advanced studies in artificial intelligence, machine learning, and cybersecurity.",
    duration: "4 years",
    department: "School of Engineering",
    prerequisites: ["Mathematics", "Programming"],
    careerPaths: ["Software Developer", "Data Scientist"],
    level: "Undergraduate",
    location: "Online / On-campus",
    fees: "$30,000/year",
    exampleEmployer: "Google, Tesla, Amazon",
  },
  {
    id: "2",
    title: "Electrical Engineering",
    description:
      "An in-depth program focusing on circuits, electronics, and electromagnetism, preparing students for careers in electrical and systems engineering. The program covers topics such as circuit design, signal processing, control systems, and renewable energy technologies. Students will gain hands-on experience in designing and testing electrical systems, working with advanced equipment, and applying theoretical knowledge to solve real-world problems. Graduates are well-equipped for roles in industries ranging from telecommunications to robotics, power generation, and automation.",
    duration: "4 years",
    department: "School of Engineering",
    prerequisites: ["Physics", "Mathematics"],
    careerPaths: ["Electrical Engineer", "Systems Engineer"],
    level: "Undergraduate",
    location: "On-campus",
    fees: "$28,000/year",
    exampleEmployer: "Tesla, Amazon, Google",
  },
  {
    id: "3",
    title: "Data Science",
    description:
      "A program that prepares students to analyze and interpret complex data sets, using statistical methods, machine learning, and data visualization techniques.",
    duration: "2 years",
    department: "School of Science and Technology",
    prerequisites: ["Statistics", "Programming", "Mathematics"],
    careerPaths: ["Data Analyst", "Data Scientist", "Machine Learning Engineer"],
    level: "Master's",
    location: "Online / On-campus",
    fees: "$35,000/year",
    exampleEmployer: "Amazon, Tesla, Google",
  },
];

function DegreeCardListContainer() {
  return (
    <MotiView
      from={{ opacity: 0, translateY: 20 }}
      animate={{ opacity: 1, translateY: 0 }}
      delay={500}
      transition={{ type: "timing", duration: 300 }}
      aria-live="polite">
      <DegreeCardList degrees={degrees} />
    </MotiView>
  );
}

export default DegreeCardListContainer;
