import React from "react";
import { View, StyleSheet, Platform, Linking } from "react-native";
import CustomText from "../../components/common/general/CustomText/CustomText";
import CustomButton from "../../components/common/general/CustomButton/CustomButton";
import CustomSpacing from "../../components/common/layout/CustomSpacing/CustomSpacing";
import { EditTaskBlue, TrashCanBlue } from "../../components/svgs/common";
import { File } from "../../components/svgs/CustomCoursework";
import { WebView } from "react-native-webview";

function ActivityContainer({ activity, onEdit, onDelete }) {
  if (!activity) {
    return (
      <View style={styles.emptyContainer}>
        <CustomText text="No activity selected" style={styles.emptyText} />
      </View>
    );
  }

  const handleOpenURL = (url) => {
    if (Platform.OS === "web") {
      const win = window.open(url, "_blank");
      if (win) win.focus();
    } else {
      Linking.openURL(url).catch((err) => console.error("Failed to open URL:", err));
    }
  };

  return (
    <View style={styles.container}>
      <View style={styles.activityHeader}>
        <View style={{ flexDirection: "column" }}>
          <CustomText text={activity.type} size="s" weight="medium" style={{ color: "#667085" }} />
          <CustomText
            text={activity.title}
            weight="bold"
            size="xl"
            useTranslationText={false}
            style={{ paddingBottom: 20 }}
          />
        </View>
        <View style={styles.buttonContainer}>
          {/* <CustomButton
            text="Edit"
            styleType="primaryLight"
            onPress={onEdit}
            size="s"
            style={{ borderColor: "#05060f1a", borderWidth: 1, height: 36 }}
            leftIcon={<EditTaskBlue width={16} height={16} style={{ marginRight: 4 }} />}
            textStyle={{ fontSize: 14, fontWeight: 700 }}
          /> */}
          <CustomSpacing type="horizontal" size="m" />
          <CustomButton
            text="Delete"
            styleType="primaryLight"
            onPress={onDelete}
            size="s"
            leftIcon={<TrashCanBlue width={16} height={16} />}
            style={{ borderColor: "#05060f1a", borderWidth: 1, height: 36 }}
            textStyle={{ fontSize: 14, fontWeight: 700 }}
          />
        </View>
      </View>

      <CustomSpacing type="vertical" size="m" />

      {/* Activity Instructions */}
      <CustomText text="Activity Instructions" weight="ultraBold" size="m" />
      <CustomSpacing type="vertical" size="xl" />
      <View style={{ flex: 1 }}>
        <WebView source={{ html: activity?.description || "<p>No content available</p>" }} originWhitelist={["*"]} />
      </View>

      <CustomSpacing type="vertical" size="xl" />

      {/* Only show Learning Objectives for non-Resource types */}
      {activity.type !== "Resource" && (
        <>
          <CustomText text="Learning Objectives" weight="ultraBold" size="m" />
          <CustomSpacing type="vertical" size="xl" />
          {activity.objectives?.length > 0 ? (
            activity.objectives.map((obj, index) => (
              <View key={obj.id} style={styles.objectiveContainer}>
                <CustomText
                  text={`${index + 1}. ${obj.objective}`}
                  size="m"
                  style={styles.text}
                  useTranslationText={false}
                />
                <CustomSpacing type="vertical" size="s" />
              </View>
            ))
          ) : (
            <CustomText
              text="No learning objectives available"
              size="m"
              style={styles.text}
              useTranslationText={false}
            />
          )}
          <CustomSpacing type="vertical" size="xl" />
        </>
      )}

      {/* Contextual Alignment */}
      {activity.type !== "Resource" && (
        <>
          <CustomText text="Contextual Alignment" weight="ultraBold" size="m" />
          <CustomSpacing type="vertical" size="xl" />
          <CustomText text={activity.contextualAlignment} size="m" style={styles.text} useTranslationText={false} />
          <CustomSpacing type="vertical" size="xl" />
        </>
      )}

      {/* Files section */}
      {activity.type === "Resource" && activity.files?.length > 0 && (
        <>
          <CustomText text="Files" weight="ultraBold" size="m" />
          <CustomSpacing type="vertical" size="m" />
          {activity.files.map((file, index) => (
            <View key={index} style={styles.fileContainer}>
              <File width={16} height={16} style={{ paddingRight: 8 }} />
              <CustomText
                text={file.fileName}
                weight="bold"
                style={styles.fileText}
                useTranslationText={false}
                onPress={() => handleOpenURL(file.fileURL)}
              />
            </View>
          ))}
          <CustomSpacing type="vertical" size="xl" />
        </>
      )}

      {/* Show Video URL for Resource type */}
      {activity.type === "Resource" && activity.videoUrl && (
        <>
          <CustomText text="Video URL" weight="ultraBold" size="m" />
          <CustomSpacing type="vertical" size="m" />
          <View>
            <CustomText
              text={activity.videoUrl}
              useTranslationText={false}
              style={styles.linkText}
              onPress={() => {
                if (Platform.OS === "web") {
                  const win = window.open(activity.videoUrl, "_blank");
                  if (win) win.focus();
                } else {
                  Linking.openURL(activity.videoUrl);
                }
              }}
            />
          </View>
          <CustomSpacing type="vertical" size="xl" />
        </>
      )}
    </View>
  );
}

const styles = StyleSheet.create({
  container: {
    flex: 1,
    padding: 20,
  },
  activityHeader: {
    flexDirection: "row",
    borderBottomWidth: 1,
    borderBottomColor: "#E5E7EB",
    justifyContent: "space-between",
    marginVertical: 10,
  },
  buttonContainer: {
    flexDirection: "row",
    justifyContent: "space-between",
    alignContent: "center",
  },
  text: {
    color: "#344054",
  },
  fileContainer: {
    padding: 10,
    backgroundColor: "#F9FAFB",
    borderRadius: 8,
    flexDirection: "row",
    alignItems: "center",
    marginBottom: 12,
  },
  fileText: {
    color: "#6B7280",
  },
  emptyContainer: {
    flex: 1,
    justifyContent: "center",
    alignItems: "center",
    backgroundColor: "#F9FAFB",
  },
  emptyText: {
    color: "#6B7280",
  },
  objectiveContainer: {
    paddingLeft: 8,
    marginBottom: 8,
  },
  linkText: {
    color: "blue",
    textDecorationLine: "underline",
    textAlign: "left",
    borderWidth: 0,
    // shadowColor: "transparent",
  },
});

export default ActivityContainer;
