import React, { useState, useEffect } from "react";
import { View, StyleSheet, ActivityIndicator } from "react-native";
import DefaultLayout from "../layouts/DefaultLayout";
import { useAppState, actions } from "../contexts/AppStateContext";
import { useNavigation } from "@react-navigation/native";
import CustomText from "../components/common/general/CustomText/CustomText";
import CustomSpacing from "../components/common/layout/CustomSpacing/CustomSpacing";
import CustomButton from "../components/common/general/CustomButton/CustomButton";
import { ExitDoor } from "../components/svgs/common";
import RoundedLabel from "../components/common/label/RoundedLabel/RoundedLabel";
import DeleteResourceModalContainer from "../components/content/ContentModalContainers/DeleteResourceModalContainer";
import ModuleContainer from "../containers/customCoursework/ModuleContainer";
import ActivityContainer from "../containers/customCoursework/ActivityContainer";
import EditModuleNameModalContainer from "../components/content/ContentModalContainers/EditModuleNameModalContainer";
import AddModuleModalContainer from "../components/content/ContentModalContainers/AddModuleModalContainer";
import EditActivityModalContainer from "../containers/customCoursework/EditActivityModalContainer/EditActivityModalContainer";
import {
  GetCourseByIdDocument,
  useCreateAiTutorMutation,
  useGetCourseByIdQuery,
  useCreateModuleMutation,
  useDeleteModuleCascadeMutation,
  useDeleteAiTutorMutation,
  useEditAiTutorMutation,
  useUpdateModuleMutation,
  useCreateAxioResourceMutation,
  useUpdateAxioResourceMutation,
  useDeleteAxioResourceMutation,
  useUpdateCourseMutation,
} from "../graphql/generated/graphql";
import Sentry from "../utils/sentry";

function CustomCourseworkModulesView({ route }) {
  const { dispatch } = useAppState();
  const navigation = useNavigation();
  const courseId = route.params?.courseId;

  // States
  const [deleteResourceModalVisible, setDeleteResourceModalVisible] = useState(false);
  const [deleteResourceType, setDeleteResourceType] = useState(null);
  const [resourceToDelete, setResourceToDelete] = useState(null);
  const [selectedModule, setSelectedModule] = useState(null);
  const [selectedActivity, setSelectedActivity] = useState(null);
  const [editActivityModalVisible, setEditActivityModalVisible] = useState(false);
  const [editModuleNameModalVisible, setEditModuleNameModalVisible] = useState(false);
  const [addModuleModalVisible, setAddModuleModalVisible] = useState(false);
  const [editingModule, setEditingModule] = useState(null);
  const [editingActivity, setEditingActivity] = useState(null);
  const [activityModalError, setActivityModalError] = useState(null);

  // Queries
  const { data: courseData, loading: courseLoading } = useGetCourseByIdQuery({
    variables: {
      id: courseId,
    },
  });

  const data = courseData?.getCourseById?.data?.[0];
  const isDraft = data?.isDraft;
  const loading = courseLoading;
  const currentModule = selectedModule || data?.modules?.[0];

  // Mutations
  const [createAiTutorMutation] = useCreateAiTutorMutation({
    refetchQueries: [{ query: GetCourseByIdDocument, variables: { id: courseId } }],
  });

  const [editAiTutorMutation] = useEditAiTutorMutation({
    refetchQueries: [{ query: GetCourseByIdDocument, variables: { id: courseId } }],
  });

  const [createModuleMutation] = useCreateModuleMutation({
    refetchQueries: [{ query: GetCourseByIdDocument, variables: { id: courseId } }],
  });

  const [updateModuleMutation] = useUpdateModuleMutation({
    refetchQueries: [{ query: GetCourseByIdDocument, variables: { id: courseId } }],
  });

  const [deleteModuleMutation] = useDeleteModuleCascadeMutation({
    refetchQueries: [{ query: GetCourseByIdDocument, variables: { id: courseId } }],
  });

  const [deleteAiTutorMutation] = useDeleteAiTutorMutation({
    refetchQueries: [{ query: GetCourseByIdDocument, variables: { id: courseId } }],
  });

  const [createAxioResourceMutation] = useCreateAxioResourceMutation({
    refetchQueries: [{ query: GetCourseByIdDocument, variables: { id: courseId } }],
  });

  const [updateAxioResourceMutation] = useUpdateAxioResourceMutation({
    refetchQueries: [{ query: GetCourseByIdDocument, variables: { id: courseId } }],
  });

  const [deleteAxioResourceMutation] = useDeleteAxioResourceMutation({
    refetchQueries: [{ query: GetCourseByIdDocument, variables: { id: courseId } }],
  });

  const [updateCourseMutation] = useUpdateCourseMutation({
    refetchQueries: [{ query: GetCourseByIdDocument, variables: { id: courseId } }],
  });

  useEffect(() => {
    if (data) {
      // Update selectedModule if it's stale
      if (selectedModule) {
        const updatedModule = data.modules.find((m) => m.id === selectedModule.id);
        if (updatedModule) {
          setSelectedModule(updatedModule);
        } else {
          // If the module was deleted, reset selectedModule
          setSelectedModule(null);
        }
      }

      // Update selectedActivity if it's stale
      if (selectedActivity) {
        let updatedActivity = null;
        data.modules.forEach((module) => {
          const items = [...(module.resources?.[0]?.aitutors || []), ...(module.resources?.[0]?.pages || [])];
          const activity = items.find((item) => item.id === selectedActivity.id);
          if (activity) {
            updatedActivity = activity;
          }
        });
        if (updatedActivity) {
          setSelectedActivity(updatedActivity);
        } else {
          // If the activity was deleted, reset selectedActivity
          setSelectedActivity(null);
        }
      }
    }
  }, [data]);

  // Activity Handlers
  const handleSaveActivity = async (activityData) => {
    try {
      const { title, description, objectives, files, videoUrl, type, id, contextText } = activityData;

      if (!title) {
        setActivityModalError("Title is required");
        throw new Error("Title is required");
      }

      if (!description) {
        setActivityModalError("Description is required");
        throw new Error("Description is required");
      }

      const cleanedDescription = description?.replace(/\n\s+/g, "").trim() || "";
      const encodedDescription = encodeURIComponent(cleanedDescription);

      console.log("Activity type:", type);
      console.log("Current module:", currentModule);

      if (type === "Resource") {
        if (!currentModule?.id) {
          setActivityModalError("Module ID is required");
          throw new Error("Module ID is required");
        }

        // Prepare file data for upload
        const preparedFiles =
          files?.map((file) => ({
            fileId: file.fileId || String(Date.now()),
            fileName: file.fileName,
            fileData: file.fileData,
            contentType: file.contentType,
            size: file.size,
          })) || [];

        if (id) {
          console.log("Updating resource with data:", {
            id,
            name: title,
            description: encodedDescription,
            files: preparedFiles,
            videoUrl: videoUrl || "",
            order: editingActivity?.order || 0,
          });

          const response = await updateAxioResourceMutation({
            variables: {
              id,
              resource: {
                name: title,
                description: encodedDescription,
                files: preparedFiles,
                videoUrl: videoUrl || "",
                order: editingActivity?.order || 0,
              },
            },
          });

          if (response?.data?.updateAxioResource?.success) {
            dispatch({
              type: actions.SET_META,
              payload: { keypressIsListening: true },
            });
            setEditActivityModalVisible(false);
            setEditingActivity(null);
            setActivityModalError(null);
          } else {
            setActivityModalError(response?.data?.updateAxioResource?.message || "Failed to edit resource");
            throw new Error(response?.data?.updateAxioResource?.message || "Failed to edit resource");
          }
        } else {
          console.log("Creating new resource with data:", {
            moduleId: currentModule.id,
            name: title,
            description: encodedDescription,
            files: preparedFiles,
            videoUrl: videoUrl || "",
            order: editingActivity?.order || 0,
          });

          const response = await createAxioResourceMutation({
            variables: {
              moduleId: currentModule.id,
              order: editingActivity?.order || 0,
              name: title,
              description: encodedDescription,
              files: preparedFiles,
              videoUrl: videoUrl || "",
            },
          });

          if (response?.data?.createAxioResource?.success) {
            dispatch({
              type: actions.SET_META,
              payload: { keypressIsListening: true },
            });
            setEditActivityModalVisible(false);
            setSelectedActivity(null);
            setActivityModalError(null);
          } else {
            setActivityModalError(response?.data?.createAxioResource?.message || "Failed to create resource");
            throw new Error(response?.data?.createAxioResource?.message || "Failed to create resource");
          }
        }
      } else if (type === "Interactive Learning Experience") {
        if (!objectives || objectives.length === 0) {
          setActivityModalError("Objectives are required");
          throw new Error("Objectives are required");
        }

        const formattedObjectives = objectives.map((obj, idx) => ({
          objective: obj,
          sequence: idx + 1,
        }));

        if (id) {
          const response = await editAiTutorMutation({
            variables: {
              id,
              title,
              description: encodedDescription,
              objectives: formattedObjectives,
            },
          });

          if (response?.data?.editAITutor?.success) {
            dispatch({
              type: actions.SET_META,
              payload: { keypressIsListening: true },
            });
            setEditActivityModalVisible(false);
            setEditingActivity(null);
            setActivityModalError(null);
          } else {
            setActivityModalError(response?.data?.editAITutor?.message || "Failed to edit AI Tutor");
            throw new Error(response?.data?.editAITutor?.message || "Failed to edit AI Tutor");
          }
        } else {
          if (!currentModule?.resources?.[0]?.id) {
            setActivityModalError("Parent resource ID is required");
            throw new Error("Parent resource ID is required");
          }

          const response = await createAiTutorMutation({
            variables: {
              title,
              description: encodedDescription,
              parentResourceId: currentModule.resources[0].id,
              objectives: formattedObjectives,
              context: contextText || "",
            },
          });

          if (response?.data?.createAITutor?.success) {
            dispatch({
              type: actions.SET_META,
              payload: { keypressIsListening: true },
            });
            setEditActivityModalVisible(false);
            setSelectedActivity(null);
            setActivityModalError(null);
          } else {
            setActivityModalError(response?.data?.createAITutor?.message || "Failed to create AI Tutor");
            throw new Error(response?.data?.createAITutor?.message || "Failed to create AI Tutor");
          }
        }
      }
    } catch (error) {
      console.error("Error saving activity:", error);
      Sentry.captureException(error);
    }
  };

  const handleDeleteActivity = (activity) => {
    setResourceToDelete(activity);
    setDeleteResourceType("Activity");
    setDeleteResourceModalVisible(true);
  };

  const handleOpenAddActivity = () => {
    dispatch({
      type: actions.SET_META,
      payload: { keypressIsListening: false },
    });
    setEditActivityModalVisible(true);
  };

  // Module Handlers
  const handleAddModule = async (moduleName, moduleOrder) => {
    try {
      const order = moduleOrder ? parseFloat(moduleOrder) : 0;

      const response = await createModuleMutation({
        variables: {
          title: moduleName,
          order: order,
          parentCourseId: courseId,
        },
      });

      if (response?.data?.createModuleAndResource?.success) {
        dispatch({
          type: actions.SET_META,
          payload: { keypressIsListening: true },
        });
        setAddModuleModalVisible(false);
      } else {
        throw new Error(response?.data?.createModuleAndResource?.message || "Failed to create module");
      }
    } catch (error) {
      Sentry.captureException(error);
      console.error("Error creating module:", error);
    }
  };

  const handleUpdateModuleName = async (originalName, newName, order, moduleId) => {
    try {
      const orderValue = order === "" ? 0 : parseFloat(order);

      const response = await updateModuleMutation({
        variables: {
          id: moduleId,
          title: newName,
          order: orderValue,
        },
      });

      if (response?.data?.updateModule?.success) {
        dispatch({
          type: actions.SET_META,
          payload: { keypressIsListening: true },
        });
        setEditModuleNameModalVisible(false);
        setEditingModule(null);
      } else {
        throw new Error(response?.data?.updateModule?.message || "Failed to update module");
      }
    } catch (error) {
      Sentry.captureException(error);
      console.error("Error updating module:", error);
    }
  };

  const handleDeleteModule = async (module) => {
    setResourceToDelete(module);
    setDeleteResourceType("Module");
    setDeleteResourceModalVisible(true);
  };

  const handleOpenAddModule = () => {
    dispatch({
      type: actions.SET_META,
      payload: { keypressIsListening: false },
    });
    setAddModuleModalVisible(true);
  };

  const handleEditModule = (module) => {
    dispatch({
      type: actions.SET_META,
      payload: { keypressIsListening: false },
    });
    setEditingModule({
      ...module,
      order: module.order ?? 0,
    });
    setEditModuleNameModalVisible(true);
  };

  // General Handlers
  const handleConfirmDelete = async () => {
    if (deleteResourceType === "Module") {
      try {
        const response = await deleteModuleMutation({
          variables: {
            id: resourceToDelete.id,
          },
        });

        if (response?.data?.deleteModuleCascade?.success) {
          setDeleteResourceModalVisible(false);
          setResourceToDelete(null);
          if (selectedModule?.id === resourceToDelete.id) {
            setSelectedModule(null);
          }
        } else {
          throw new Error(response?.data?.deleteModuleCascade?.message || "Failed to delete module");
        }
      } catch (error) {
        Sentry.captureException(error);
        console.error("Error deleting module:", error);
      }
    } else if (deleteResourceType === "Activity") {
      try {
        if (resourceToDelete.type === "Resource") {
          const response = await deleteAxioResourceMutation({
            variables: {
              id: resourceToDelete.id,
            },
          });

          if (response?.data?.deleteAxioResource?.success) {
            setDeleteResourceModalVisible(false);
            setResourceToDelete(null);
            if (selectedActivity?.id === resourceToDelete.id) {
              setSelectedActivity(null);
            }
          } else {
            throw new Error(response?.data?.deleteAxioResource?.message || "Failed to delete resource");
          }
        } else {
          const response = await deleteAiTutorMutation({
            variables: {
              id: resourceToDelete.id,
            },
          });

          if (response?.data?.deleteAITutor?.success) {
            setDeleteResourceModalVisible(false);
            setResourceToDelete(null);
            if (selectedActivity?.id === resourceToDelete.id) {
              setSelectedActivity(null);
            }
          } else {
            throw new Error(response?.data?.deleteAITutor?.message || "Failed to delete activity");
          }
        }
      } catch (error) {
        Sentry.captureException(error);
        console.error("Error deleting activity:", error);
      }
    }
  };

  const handleItemOptionsPress = (action, activity) => {
    console.log("handleItemOptionsPress:", action, activity);
    if (action === "edit") {
      dispatch({
        type: actions.SET_META,
        payload: { keypressIsListening: false },
      });
      setEditingActivity(activity);
      setEditActivityModalVisible(true);
    } else if (action === "delete") {
      handleDeleteActivity(activity);
    }
  };

  const handleItemToggle = (item) => {
    console.log("Toggle item:", item);
  };

  const handleSelectModule = (module) => {
    setSelectedModule(module);
    setSelectedActivity(null);
  };

  const handlePublishPress = async () => {
    try {
      await updateCourseMutation({
        variables: {
          id: courseId,
          isDraft: false,
        },
      });
    } catch (error) {
      Sentry.captureException(error);
      console.error("Error publishing course:", error);
    }
  };

  const handleExitPress = () => {
    navigation.navigate("Custom School Student");
  };

  if (loading) {
    return (
      <View style={styles.loaderContainer}>
        <ActivityIndicator size="large" color="#0000ff" />
      </View>
    );
  }

  return (
    <View style={{ flex: 1, backgroundColor: "#fff" }}>
      {/* Header */}
      <View style={styles.header}>
        <View style={styles.headerLeft}>
          <View style={styles.exitButtonContainer}>
            <CustomButton leftIcon={<ExitDoor />} onPress={handleExitPress} size="s" styleType="transparent" />
          </View>
          <CustomSpacing type="horizontal" size="m" />
          <CustomText text={data.name} useTranslationText={false} weight="bold" size="m" />
          <CustomSpacing type="horizontal" size="m" />
          {isDraft ? (
            <RoundedLabel
              text="Draft"
              textStyle={{ fontSize: 14 }}
              style={styles.draftLabel}
              padding={[8, 6]}
              isRounded="true"
            />
          ) : (
            <RoundedLabel
              text="Published"
              style={styles.publisheLabel}
              textStyle={styles.publishedText}
              padding={[8, 6]}
              isRounded="true"
            />
          )}
        </View>
        {isDraft && (
          <View style={styles.headerRight}>
            <CustomButton
              size="s"
              text="Publish"
              styleType="primary"
              textStyle={{ fontSize: 14, fontWeight: 700 }}
              onPress={handlePublishPress}
            />
          </View>
        )}
      </View>

      {/* Main Content */}
      <DefaultLayout
        menuType="customCoursework"
        withPadding={false}
        onSelectModule={handleSelectModule}
        onSelectActivity={setSelectedActivity}
        selectedModule={selectedModule}
        selectedActivity={selectedActivity}
        onAddModule={handleOpenAddModule}>
        <View style={{ flex: 1 }}>
          {loading ? (
            <View style={styles.loaderContainer}>
              <ActivityIndicator size="large" color="#0000ff" />
            </View>
          ) : selectedActivity ? (
            <ActivityContainer
              activity={selectedActivity}
              style={{ paddingTop: 20 }}
              onEdit={() => handleItemOptionsPress("edit", selectedActivity)}
              onDelete={() => handleItemOptionsPress("delete", selectedActivity)}
            />
          ) : currentModule ? (
            <ModuleContainer
              module={currentModule}
              selectedItemId={route.params?.itemId}
              onEditModule={handleEditModule}
              onDeleteModule={handleDeleteModule}
              onOpenAddActivity={handleOpenAddActivity}
              onItemOptionsPress={handleItemOptionsPress}
              onItemToggle={handleItemToggle}
            />
          ) : null}

          {/* Modals */}
          <DeleteResourceModalContainer
            visible={deleteResourceModalVisible}
            onClose={() => setDeleteResourceModalVisible(false)}
            onDeleteResource={handleConfirmDelete}
            type={deleteResourceType}
          />

          <EditActivityModalContainer
            visible={editActivityModalVisible}
            onClose={() => {
              setEditActivityModalVisible(false);
              setEditingActivity(null);
            }}
            onSave={handleSaveActivity}
            error={activityModalError}
            existingActivity={editingActivity}
          />

          <EditModuleNameModalContainer
            visible={editModuleNameModalVisible}
            onClose={() => {
              setEditModuleNameModalVisible(false);
              setEditingModule(null);
            }}
            moduleName={editingModule?.title}
            moduleId={editingModule?.id}
            onUpdateModuleName={handleUpdateModuleName}
            hasOrder={true}
            currentOrder={editingModule?.order}
          />

          <AddModuleModalContainer
            visible={addModuleModalVisible}
            onClose={() => setAddModuleModalVisible(false)}
            onAddModule={handleAddModule}
            hasOrder={true}
          />
        </View>
      </DefaultLayout>
    </View>
  );
}

const styles = StyleSheet.create({
  loaderContainer: {
    flex: 1,
    justifyContent: "center",
    alignItems: "center",
  },
  header: {
    flexDirection: "row",
    justifyContent: "space-between",
    borderBottomColor: "#D0D5DD",
    borderBottomWidth: 1,
  },
  headerLeft: {
    paddingVertical: 10,
    flexDirection: "row",
    alignItems: "center",
  },
  headerRight: {
    flexDirection: "row",
    justifyContent: "space-around",
    alignItems: "center",
    paddingRight: 20,
  },
  exitButtonContainer: {
    borderRightColor: "#D0D5DD",
    borderRightWidth: 1,
    paddingHorizontal: 10,
  },
  draftLabel: {
    backgroundColor: "#EAECF0",
    borderRadius: 360,
  },
  publisheLabel: {
    backgroundColor: "#66C61C",
    borderRadius: 360,
  },
  publishedText: {
    color: "#FFFFFF",
    fontSize: 14,
  },
});

export default CustomCourseworkModulesView;
