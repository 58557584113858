import React, { useState, useEffect, useRef, useCallback } from "react";
import { View, ScrollView, StyleSheet } from "react-native";
import { useAppState } from "../../../contexts/AppStateContext";
import MessageContainer from "../MessageContainer/MessageContainer";
import CustomButton from "../../../components/common/general/CustomButton/CustomButton";
import { DownArrow } from "../../../components/svgs/common";
import { useChatBar } from "../../../contexts/ChatBarContext";
import ProcessingMessageBox from "../../../components/chat/LoadingMessage/ProcessingMessageBox";
import { useFocusEffect } from "@react-navigation/native";
import CustomSpacing from "../../../components/common/layout/CustomSpacing/CustomSpacing";

const CONTAINER_PADDING_BOTTOM = 16;

function ChatContainer({ chatData }) {
  const { state } = useAppState();
  const [conversationData, setConversationData] = useState([]);
  const scrollViewRef = useRef(null);
  const [isUserScrolling, setIsUserScrolling] = useState(false);
  const [downButton, setDownButton] = useState(false);
  const [humanMessageOffset, setHumanMessageOffset] = useState(0);
  const [scrollViewHeight, setScrollViewHeight] = useState(undefined);
  const [parentHeight, setParentHeight] = useState(0);

  const [isUserInteracted, setIsUserInteracted] = useState(false);

  const isAtBottomRef = useRef(true);
  const { state: chatBarState } = useChatBar();

  useFocusEffect(
    useCallback(() => {
      return () => {
        setIsUserInteracted(false);
      };
    }, [])
  );

  const handleScroll = useCallback((event) => {
    const { layoutMeasurement, contentOffset, contentSize } = event.nativeEvent;
    const isAtBottom = layoutMeasurement.height + contentOffset.y >= contentSize.height - 50;

    if (!isAtBottom) {
      setIsUserScrolling(true);
      setDownButton(true);
    } else {
      setIsUserScrolling(false);
      setDownButton(false);
    }

    isAtBottomRef.current = isAtBottom;
  }, []);

  const scrollToBottom = (animated = false) => {
    if (scrollViewRef.current) {
      scrollViewRef.current.scrollToEnd({ animated });
    }
  };

  const scrollToBottomButton = () => {
    setDownButton(false);
    scrollToBottom(true);
  };

  useEffect(() => {
    if (scrollViewRef.current) {
      scrollViewRef.current.setAttribute("data-sensitive", "true");
    }
  }, []);

  useEffect(() => {
    if (isUserInteracted && humanMessageOffset > 0 && !isUserScrolling) {
      scrollViewRef.current.scrollTo({ y: humanMessageOffset });
    } else {
      scrollToBottom();
    }
  }, [humanMessageOffset]);

  useEffect(() => {
    if (state.content?.author?.toLowerCase() === "human") {
      setIsUserInteracted(true);
    } else if (!isUserInteracted) {
      scrollToBottom();
    }
  }, [state.content?.author]);

  useEffect(() => {
    const conversation = chatData?.conversation ? [...chatData.conversation].reverse() : [];
    setConversationData(conversation);
  }, [chatData]);

  useEffect(() => {
    if (!state.content?.message) return;
    if (state.content?.author?.toLowerCase() !== "ai") return;

    const conversation = chatData?.conversation ? [...chatData.conversation].reverse() : [];

    const newAIMessage = {
      content: state.content?.message,
      sender: state.content?.author?.toLowerCase(),
    };

    const isNew = !conversation.some((item) => item.content === newAIMessage.content);
    const combinedChatData = isNew ? [...conversation, newAIMessage] : conversation;

    setConversationData(combinedChatData);
  }, [state.content]);

  const handleOnLayout = (event, item, array) => {
    const hasNoHumanMessageYet = array.every((item) => item.sender !== "human");
    const isLastAiMessage = array.findLast((item) => item.sender === "ai")?.id === item.id;

    if (hasNoHumanMessageYet && isLastAiMessage) {
      const lastAiMessageOffsetTop = event.nativeEvent.layout.y;

      setScrollViewHeight(lastAiMessageOffsetTop + parentHeight - CONTAINER_PADDING_BOTTOM);
    } else {
      const isLastHumanMessage = array.findLast((item) => item.sender === "human")?.id === item.id;

      if (isLastHumanMessage && isUserInteracted) {
        const humanMessageOffsetFromTop = event.nativeEvent.layout.y;

        setHumanMessageOffset(humanMessageOffsetFromTop);
        setScrollViewHeight(humanMessageOffsetFromTop + parentHeight - CONTAINER_PADDING_BOTTOM);
      }
    }
  };

  return (
    <>
      <View
        style={[styles.container, { paddingBottom: CONTAINER_PADDING_BOTTOM }]}
        onLayout={(event) => setParentHeight(event.nativeEvent.layout.height)}>
        <ScrollView
          ref={scrollViewRef}
          style={styles.scrollView}
          onScroll={handleScroll}
          scrollEventThrottle={16}
          maintainVisibleContentPosition={{
            minIndexForVisible: 0,
            autoscrollToTopThreshold: 10,
          }}
          showsVerticalScrollIndicator={false}
          contentContainerStyle={{ height: scrollViewHeight }}
          onContentSizeChange={() => {
            if (!isUserInteracted) {
              scrollToBottom();
            }
          }}>
          <View style={styles.view}>
            {conversationData.map((item, index, array) => (
              <View
                onLayout={(event) => {
                  handleOnLayout(event, item, array);
                }}
                key={index}>
                <MessageContainer message={item} index={index} />
              </View>
            ))}
            {chatBarState.llmMessage.meta.isLoading && (
              <View>
                <CustomSpacing type="vertical" size="s" />
                <ProcessingMessageBox message={state?.meta?.executionInfo?.message} />
              </View>
            )}
          </View>
        </ScrollView>
        {downButton && (
          <View style={styles.buttonContainer}>
            <CustomButton
              styleType="primary"
              style={styles.button}
              leftIcon={<DownArrow />}
              onPress={scrollToBottomButton}
              rightIcon={undefined}
              text={undefined}
              textProps={undefined}
              textStyle={undefined}
              disabled={false}
            />
          </View>
        )}
      </View>
    </>
  );
}

const styles = StyleSheet.create({
  container: {
    flex: 1,
    zIndex: 800,
  },
  scrollView: {
    height: 20,
    paddingHorizontal: 10,
    zIndex: 0,
  },
  view: {
    width: "100%",
    alignSelf: "center",
    maxWidth: 770,
    paddingHorizontal: 10,
  },
  button: {
    justifyContent: "center",
    position: "absolute",
    alignItems: "center",
    bottom: 10,
    width: 30,
    height: 30,
    borderRadius: 25,
    zIndex: 5,
  },
  buttonContainer: {
    alignItems: "center",
  },
  loader: {
    position: "absolute",
    left: 0,
    right: 0,
    alignItems: "center",
    justifyContent: "center",
  },
});

export default ChatContainer;
