import React, { useState } from "react";
import { MotiView } from "moti";
import { View, Text, Pressable, StyleSheet } from "react-native";
import Divider from "../common/layout/Divider/Divider";
import CustomSpacing from "../common/layout/CustomSpacing/CustomSpacing";
import CustomText from "../common/general/CustomText/CustomText";
import Degree from "../../components/svgs/common/Degree.js";

function DegreeCard({ title, description, exampleEmployer }) {
  const [isExpanded, setIsExpanded] = useState(false);
  const truncatedDescription = description.length > 100 ? description.slice(0, 100) + "..." : description;

  return (
    <MotiView
      from={{
        opacity: 0,
        translateY: 20,
      }}
      animate={{
        opacity: 1,
        translateY: 0,
      }}
      delay={1000}
      transition={{ type: "timing", duration: 500 }}
      aria-label={`Class card ${title}`}>
      <View style={styles.card}>
        <View style={styles.infoContainer}>
          <Degree />
          <CustomSpacing type="vertical" size="s" />
          <View style={styles.detailsContainer}>
            <Text style={styles.titleText} numberOfLines={2}>
              {title}
            </Text>
            <CustomText
              style={[styles.contentText, isExpanded && { height: "auto" }]}
              text={isExpanded ? description : truncatedDescription}
              size="s"
              useTranslationText={false}
            />
            <Pressable onPress={() => setIsExpanded(!isExpanded)}>
              <Text style={styles.readMoreText}>{isExpanded ? "Read Less" : "Read More"}</Text>
            </Pressable>
          </View>
          <Divider size="l" />
          <View style={styles.detailsContainer}>
            <CustomText text="Example Employers" style={styles.lable} numberOfLines={1} ellipsizeMode="tail" />
            <CustomText style={styles.exampleText} text={exampleEmployer} size="s" useTranslationText={false} />
          </View>
        </View>
      </View>
    </MotiView>
  );
}

const styles = StyleSheet.create({
  readMoreText: {
    color: "#3E68FE",
    fontSize: 14,
    fontWeight: "600",
    marginTop: 5,
  },
  contentText: {
    color: "#667085",
    lineHeight: 18,
    height: 72,
    overflow: "hidden",
  },
  exampleText: {
    color: "#667085",
    lineHeight: 18,
    overflow: "hidden",
  },
  card: {
    width: 350,
    height: "auto",
    margin: 8,
    borderRadius: 24,
    borderWidth: 1,
    borderColor: "#E0E0E0",
    backgroundColor: "#fff",
  },
  infoContainer: {
    flexDirection: "column",
    justifyContent: "flex-start",
    padding: 24,
  },
  detailsContainer: {
    alignItems: "flex-start",
    width: "100%",
  },
  lable: {
    color: "#667085",
    fontSize: 18,
    marginBottom: 10,
  },
  titleText: {
    color: "#000",
    fontSize: 20,
    fontWeight: "700",
    marginBottom: 10,
  },
});

export default DegreeCard;
