import { View, StyleSheet, Platform } from "react-native";
import React, { useEffect, useState } from "react";
import { useAppConfig } from "../../../AppConfigProvider";
import { RouteProp, useNavigation, useRoute } from "@react-navigation/native";
import { useCompletePendingInviteMutation } from "../../../graphql/generated/graphql";
import CustomText from "../../../components/common/general/CustomText/CustomText";
import CustomButton from "../../../components/common/general/CustomButton/CustomButton";
import CustomSpacing from "../../../components/common/layout/CustomSpacing/CustomSpacing";
import { announceForAccessibility } from "../../../utils/accessibility";
import { NativeStackNavigationProp } from "@react-navigation/native-stack";
import { RootStackParamList } from "../../../navigation/AppNavigator.web";
import Logo from "../../../components/general/logos/Logo/Logo";
import CustomTextInput from "../../../components/common/general/CustomTextInput/CustomTextInput";
import Loader from "../../../components/common/status/Loader/Loader";
import { PasswordChecklist, validatePassword } from "../../../components/common/password/passwordChecklist";

function SetPasswordContainer() {
  const appConfig = useAppConfig();
  const navigation = useNavigation<NativeStackNavigationProp<RootStackParamList>>();
  const { params } = useRoute<RouteProp<RootStackParamList, "Set Password">>();

  const isExistingUser = params?.f === btoa("isExistingUser");

  const [completePendingInvite, { loading }] = useCompletePendingInviteMutation();

  const [errorMessage, setErrorMessage] = useState("");
  const [newPassword, setNewPassword] = useState("");
  const [invitationConfirmed, setInvitationConfirmed] = useState(false);

  useEffect(() => {
    if (Platform.OS === "ios" && errorMessage) {
      announceForAccessibility({ message: "Error alert" + errorMessage });
    }
  }, [errorMessage]);

  const domainKey = appConfig.key;

  const handleNewPasswordChange = (text) => {
    setNewPassword(text);
  };

  const handleConfirmInvitation = async () => {
    setErrorMessage("");

    if (!isExistingUser) {
      const isValidPassword = validatePassword(newPassword);

      if (isValidPassword !== true) {
        setErrorMessage(isValidPassword as string);
        return;
      }
    }

    if (!params?.token) {
      setErrorMessage("No token found. Please open the link from your email.");
      return;
    }

    try {
      const result = await completePendingInvite({
        variables: {
          token: params?.token,
          ...(newPassword && { newPassword }),
        },
      });

      if (!result.data.completePendingInvite.success) {
        setErrorMessage(result.data.completePendingInvite.message);
        return;
      }

      setInvitationConfirmed(true);
    } catch (error) {
      setErrorMessage("Operation failed.");
      return;
    }
  };

  return (
    <View style={[styles.container, { maxWidth: 400, width: "100%" }]}>
      <View accessible={true} accessibilityRole="header" style={styles.container}>
        <Logo name={domainKey} height={25} width={92} />
        <CustomSpacing type="vertical" size="xl" />
        <CustomSpacing type="vertical" size="xl" />
        <CustomText
          style={undefined}
          text={isExistingUser ? "Please confirm invitation" : "Create a password"}
          size="s"
          weight="ultraBold"
          textType="display"
        />
        <CustomSpacing type="vertical" size="m" />
        {!isExistingUser && (
          <CustomText style={{ color: "#667085" }} text={"Set a password to secure your account."} size="s" />
        )}
      </View>
      {!invitationConfirmed ? (
        <>
          {isExistingUser ? (
            loading ? (
              <Loader />
            ) : (
              <>
                <CustomSpacing type="vertical" size="xxl" />
                <CustomButton
                  disabled={undefined}
                  leftIcon={undefined}
                  rightIcon={undefined}
                  textStyle={undefined}
                  textProps={undefined}
                  text="Confirm Invitation"
                  styleType="primary"
                  style={styles.button}
                  onPress={handleConfirmInvitation}
                />
                {errorMessage ? (
                  <>
                    <CustomSpacing type="vertical" size="s" />
                    <CustomText role="alert" text={errorMessage} size="s" style={styles.errorMessage} />
                    <CustomSpacing type="vertical" size="s" />
                  </>
                ) : (
                  <CustomSpacing type="vertical" size="xl" />
                )}
              </>
            )
          ) : (
            <View style={styles.container}>
              <View style={styles.passwordContainer}>
                <CustomSpacing type="vertical" size="m" />
                <CustomText style={undefined} text="Password" size="s" />
                <CustomSpacing type="vertical" size="xs" />
                <CustomTextInput
                  style={errorMessage && styles.textInputError}
                  value={newPassword}
                  placeholder="New Password..."
                  onChangeText={(text) => handleNewPasswordChange(text)}
                  secureTextEntry
                  aria-label="Password text input"
                  accessibilityHint="Enter your password"
                />
                <CustomSpacing type="vertical" size="xl" />
                <PasswordChecklist rules={["length", "number", "capital", "lowercase"]} value={newPassword} />
              </View>
              <CustomSpacing type="vertical" size="m" />
              {loading ? (
                <Loader />
              ) : (
                <CustomButton
                  accessibilityHint="Press to set password"
                  text="Set Password and Log In"
                  styleType="primary"
                  onPress={handleConfirmInvitation}
                  disabled={undefined}
                  leftIcon={undefined}
                  rightIcon={undefined}
                  textStyle={undefined}
                  textProps={undefined}
                  style={styles.button}
                />
              )}
              <CustomSpacing type="vertical" size="m" />

              <View aria-live={errorMessage ? "assertive" : "off"}>
                {errorMessage ? (
                  <>
                    <CustomSpacing type="vertical" size="s" />
                    <CustomText role="alert" text={errorMessage} size="s" style={styles.errorMessage} />
                    <CustomSpacing type="vertical" size="s" />
                  </>
                ) : (
                  <CustomSpacing type="vertical" size="xl" />
                )}
              </View>
            </View>
          )}
        </>
      ) : (
        <>
          <CustomText text="Invitation confirmed" size="s" style={undefined} />
          <CustomSpacing type="vertical" size="m" />
          <CustomButton
            text="Log In"
            styleType="primary"
            onPress={() => navigation.navigate("Login")}
            disabled={undefined}
            leftIcon={undefined}
            rightIcon={undefined}
            textStyle={undefined}
            textProps={undefined}
            style={styles.button}
          />
        </>
      )}
    </View>
  );
}

export default SetPasswordContainer;

const styles = StyleSheet.create({
  container: {
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    width: "100%",
  },
  passwordContainer: {
    flexDirection: "column",
    justifyContent: "flex-start",
    alignItems: "flex-start",
    width: "100%",
  },
  button: {
    justifyContent: "center",
    width: "100%",
  },
  errorMessage: {
    color: "red",
    textAlign: "center",
  },
  textInputError: {
    borderColor: "red",
  },
});
