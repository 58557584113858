import React, { useState } from "react";
import { StyleSheet, View } from "react-native";
import { z } from "zod";
import useResponsiveScreen from "../../../hooks/useResponsiveScreen";
import CustomModal from "../../../components/common/general/CustomModal/CustomModal";
import CustomText from "../../../components/common/general/CustomText/CustomText";
import CustomButton from "../../../components/common/general/CustomButton/CustomButton";
import CustomTextInput from "../../../components/common/general/CustomTextInput/CustomTextInput";
import { Controller, useForm, useFieldArray } from "react-hook-form";
import { zodResolver } from "@hookform/resolvers/zod";
import NewCustomAxioActionModal from "../UsersTabContainer/NewCustomAxioActionModal";
import { PlusCircle } from "../../../components/svgIcons";

const modals = {
  inviteConfirmation: {
    title: "Success!",
    ariaLabel: "Students Invited Successfully Modal",
    warningText: "The invitations have been sent to the students.",
    actionButtonText: "OK",
  },
  inviteError: {
    title: "Error!",
    ariaLabel: "Student Invitation Error Modal",
    warningText: "An error occurred while sending the invitations. Please try again.",
    actionButtonText: "CLOSE",
  },
};

export enum InviteStudentModalState {
  CLOSED = "CLOSED",
  INVITE_CONFIRMATION = "INVITE_CONFIRMATION",
  INVITE_ERROR = "INVITE_ERROR",
}

interface Student {
  name: string;
  email: string;
}

const studentSchema = z.object({
  name: z.string().min(1, "Student name is required"),
  email: z.string().email("Invalid email address"),
});

const formSchema = z.object({
  students: z.array(studentSchema),
});

type FormData = {
  students: Student[];
};

interface InviteStudentModalProps {
  visible: boolean;
  onClose: () => void;
  onSubmit: (students: Student[]) => Promise<boolean>;
}

const InviteStudentModalContainer = ({ visible, onClose, onSubmit }: InviteStudentModalProps) => {
  const { isMedium } = useResponsiveScreen();
  const [modalState, setModalState] = useState(InviteStudentModalState.CLOSED);

  const {
    control,
    handleSubmit,
    reset,
    watch,
    formState: { errors, isValid },
  } = useForm<FormData>({
    resolver: zodResolver(formSchema),
    defaultValues: {
      students: [],
    },
    mode: "onChange",
  });

  const { fields, append, remove } = useFieldArray<FormData>({
    control,
    name: "students",
  });

  // Watch all student fields to validate
  const students = watch("students");
  const isLastStudentValid =
    students &&
    students.length > 0 &&
    students[students.length - 1]?.name?.trim() &&
    students[students.length - 1]?.email?.trim() &&
    !errors.students?.[students.length - 1];

  // Add initial student if array is empty
  React.useEffect(() => {
    if (fields.length === 0) {
      append({ name: "", email: "" } as Student);
    }
  }, [append, fields.length]);

  // Check if there are any errors in the students array
  const hasErrors = Object.keys(errors).length > 0;
  const isSubmitDisabled = hasErrors || students.length === 0 || !isValid;

  const handleFormSubmit = async (formData: FormData) => {
    try {
      // Filter out any empty or invalid entries
      const validStudents = formData.students.filter((student) => student.name?.trim() && student.email?.trim());

      const success = await onSubmit(validStudents);
      if (success) {
        reset({ students: [] });
        onClose();
        setModalState(InviteStudentModalState.INVITE_CONFIRMATION);
      } else {
        setModalState(InviteStudentModalState.INVITE_ERROR);
      }
    } catch (error) {
      console.error("Error submitting form:", error);
      setModalState(InviteStudentModalState.INVITE_ERROR);
    }
  };

  const handleModalClose = () => {
    setModalState(InviteStudentModalState.CLOSED);
  };

  // Add helper function to get validation message
  const getValidationMessage = () => {
    if (students.length === 0) {
      return "Please add at least one student";
    }

    const emptyFields = students.some((student) => !student.name?.trim() || !student.email?.trim());
    if (emptyFields) {
      return "Please fill in all required fields";
    }

    if (hasErrors) {
      return "Please fix the validation errors above";
    }

    return "";
  };

  return (
    <>
      <CustomModal
        visible={visible}
        onRequestClose={onClose}
        title="Invite Students"
        exitFill="#6B7280"
        ariaLabel="Invite students modal"
        style={{ width: isMedium ? "50%" : "90%" }}
        onShow={() => {}}
        showCloseButton={true}>
        <View style={styles.container}>
          {fields.map((field, index) => (
            <View key={field.id} style={styles.studentRow}>
              <View style={styles.formGroup}>
                <CustomText text="Student Name" size="s" style={styles.label} />
                <View style={styles.inputContainer}>
                  <Controller
                    control={control}
                    name={`students.${index}.name`}
                    render={({ field: { onChange, value } }) => (
                      <CustomTextInput
                        value={value}
                        onChangeText={onChange}
                        placeholder="John Doe"
                        error={errors.students?.[index]?.name?.message}
                        style={[styles.input, errors.students?.[index]?.name && styles.inputError]}
                      />
                    )}
                  />
                  <View style={styles.errorContainer}>
                    <CustomText
                      text={errors.students?.[index]?.name?.message || " "}
                      size="xs"
                      style={[styles.errorText, !errors.students?.[index]?.name && styles.errorTextHidden]}
                    />
                  </View>
                </View>
              </View>

              <View style={styles.formGroup}>
                <CustomText text="Email" size="s" style={styles.label} />
                <View style={styles.inputContainer}>
                  <Controller
                    control={control}
                    name={`students.${index}.email`}
                    render={({ field: { onChange, value } }) => (
                      <CustomTextInput
                        value={value}
                        onChangeText={onChange}
                        placeholder="john.doe@schoolname.org"
                        error={errors.students?.[index]?.email?.message}
                        style={[styles.input, errors.students?.[index]?.email && styles.inputError]}
                      />
                    )}
                  />
                  <View style={styles.errorContainer}>
                    <CustomText
                      text={errors.students?.[index]?.email?.message || " "}
                      size="xs"
                      style={[styles.errorText, !errors.students?.[index]?.email && styles.errorTextHidden]}
                    />
                  </View>
                </View>
              </View>

              {fields.length > 1 && (
                <CustomButton
                  text=""
                  onPress={() => remove(index)}
                  styleType="secondary"
                  size="m"
                  style={styles.removeButton}
                  leftIcon={<PlusCircle color="#DC2626" />}
                  rightIcon={null}
                  disabled={false}
                  textProps={{}}
                  textStyle={{}}
                />
              )}
            </View>
          ))}

          <CustomButton
            text="Add Another Student"
            onPress={() => append({ name: "", email: "" } as Student)}
            styleType="secondary"
            size="m"
            style={styles.addButton}
            leftIcon={<PlusCircle color="#3E68FE" />}
            rightIcon={null}
            disabled={!isLastStudentValid}
            textProps={{}}
            textStyle={{ color: !isLastStudentValid ? "#9CA3AF" : "#3E68FE" }}
          />

          {isSubmitDisabled && (
            <View style={styles.validationMessageContainer}>
              <CustomText text={getValidationMessage()} size="xs" style={styles.validationMessage} />
            </View>
          )}

          <View style={styles.buttonContainer}>
            <CustomButton
              text="Cancel"
              onPress={onClose}
              styleType="secondary"
              size="m"
              style={styles.cancelButton}
              textStyle={styles.cancelButtonText}
              leftIcon={null}
              rightIcon={null}
              disabled={false}
              textProps={{}}
            />
            <CustomButton
              text="Send Invites"
              onPress={handleSubmit(handleFormSubmit)}
              styleType="primary"
              size="m"
              style={styles.sendButton}
              textStyle={styles.sendButtonText}
              leftIcon={null}
              rightIcon={null}
              disabled={isSubmitDisabled}
              textProps={{}}
            />
          </View>
        </View>
      </CustomModal>
      <NewCustomAxioActionModal
        visible={modalState === InviteStudentModalState.INVITE_CONFIRMATION}
        onClose={handleModalClose}
        modalTexts={modals.inviteConfirmation}
      />
      <NewCustomAxioActionModal
        visible={modalState === InviteStudentModalState.INVITE_ERROR}
        onClose={handleModalClose}
        modalTexts={modals.inviteError}
      />
    </>
  );
};

const styles = StyleSheet.create({
  container: {
    padding: 24,
  },
  studentRow: {
    flexDirection: "row",
    gap: 16,
    marginBottom: 16,
    alignItems: "flex-end",
  },
  formGroup: {
    flex: 1,
  },
  label: {
    color: "#111827",
    marginBottom: 8,
    fontWeight: "500",
  },
  inputContainer: {
    minHeight: 70, // Adjust this value based on your input and error text height
  },
  input: {
    width: "100%",
  },
  removeButton: {
    backgroundColor: "transparent",
    width: 40,
    height: 40,
    padding: 0,
    marginBottom: 8,
  },
  addButton: {
    backgroundColor: "transparent",
    borderWidth: 1,
    borderColor: "#D1D5DB",
    borderRadius: 6,
    paddingHorizontal: 16,
    marginBottom: 24,
    alignSelf: "flex-start",
  },
  buttonContainer: {
    flexDirection: "row",
    justifyContent: "flex-start",
    gap: 12,
    marginTop: 8,
  },
  cancelButton: {
    backgroundColor: "transparent",
    borderWidth: 1,
    borderColor: "#D1D5DB",
    borderRadius: 6,
    paddingHorizontal: 16,
  },
  sendButton: {
    backgroundColor: "#4F46E5",
    borderRadius: 6,
    paddingHorizontal: 16,
  },
  cancelButtonText: {
    color: "#4B5563",
  },
  sendButtonText: {
    color: "#FFFFFF",
  },
  errorContainer: {
    minHeight: 20, // Adjust this value based on your error text height
  },
  errorText: {
    color: "#DC2626",
    marginTop: 4,
  },
  errorTextHidden: {
    opacity: 0,
  },
  inputError: {
    borderColor: "#DC2626",
    borderWidth: 1,
  },
  validationMessageContainer: {
    marginBottom: 12,
  },
  validationMessage: {
    color: "#DC2626",
    fontSize: 14,
  },
});

export default InviteStudentModalContainer;
