import React from "react";
import { DataTable, Menu } from "react-native-paper";
import CustomText from "../../../components/common/general/CustomText/CustomText";
import CustomButton from "../../../components/common/general/CustomButton/CustomButton";
import { FlatList, View, StyleSheet } from "react-native";
import BadgeCore from "../../../components/svgs/common/BadgeCore";
import BadgeGeneral from "../../../components/svgs/common/BadgeGeneral";
import { CoursesTabModalState } from "./CoursesTabContainer";
import { useState } from "react";
import Pill from "../../../components/common/Pill/Pill";
import BadgeElective from "../../../components/svgs/common/BadgeElective";
import { NewCustomActionButton } from "../../../components/common/general/CustomButton/NewCustomActionButton";

export const CourseTypes = {
  Core: {
    name: "Core",
    color: "#3E68FE",
    icon: <BadgeCore fill={"#FFFFFF"} />,
    textColor: "#fff",
  },
  General: {
    name: "General",
    color: "#008383",
    icon: <BadgeGeneral fill={"#FFFFFF"} />,
    textColor: "#fff",
  },
  Elective: {
    name: "Elective",
    color: "#9747FF",
    icon: <BadgeElective fill={"#FFFFFF"} />,
    textColor: "#fff",
  },
};

export interface CoursesTableProps {
  courses: {
    id: string;
    name?: string | null;
    learningStandards?: string | null;
    lengthInWeeks?: number | null;
    objectives?: Array<string> | null;
    last_modified?: string | null;
    courseType?: string | null;
    teachingFaculty: Array<{
      id: string;
      name: string;
    }>;
    enrolledStudents: Array<{
      id: string;
      name: string;
    }>;
  }[];
  onActionModalOpen: (courseId: string, courseName: string, modalType: CoursesTabModalState) => void;
}

type MenuState = {
  visible: boolean;
  x: number;
  y: number;
  courseId: string;
  courseName: string;
  courseCount: number;
};

const CoursesTable = ({ courses, onActionModalOpen }: CoursesTableProps) => {
  const [menuState, setMenuState] = useState<MenuState>({
    visible: false,
    x: 0,
    y: 0,
    courseId: null,
    courseName: null,
    courseCount: 0,
  });

  const handleOpenMenu = (event: React.MouseEvent, courseId: string, courseName: string, courseCount: number) => {
    const { pageX, pageY } = event.nativeEvent;
    setMenuState({
      visible: true,
      x: pageX - 100,
      y: pageY,
      courseId,
      courseName,
      courseCount,
    });
  };

  const handleCloseMenu = () => {
    setMenuState((prev) => ({ ...prev, visible: false, courseId: null, courseName: null, courseCount: 0 }));
  };

  const handleActionModalOpen = (modalType: CoursesTabModalState, courseId?: string, courseName?: string) => {
    onActionModalOpen(menuState.courseId ?? courseId, menuState.courseName ?? courseName, modalType);
    handleCloseMenu();
  };

  return (
    <>
      <DataTable>
        <DataTable.Header>
          <DataTable.Title style={styles.nameColumn}>
            <CustomText text="Course Name" size="s" weight="medium" style={{}} />
          </DataTable.Title>
          <DataTable.Title style={styles.typeColumn}>
            <CustomText text="Course Type" size="s" weight="medium" style={{}} />
          </DataTable.Title>
          <DataTable.Title style={styles.facultyColumn}>
            <CustomText text="Assigned Faculty" size="s" weight="medium" style={{}} />
          </DataTable.Title>
          <DataTable.Title style={styles.enrollmentColumn}>
            <CustomText text="Current Enrollment" size="s" weight="medium" style={{}} />
          </DataTable.Title>
          <DataTable.Title style={styles.actionsColumn}>
            <CustomText text="" size="s" weight="medium" style={{}} />
          </DataTable.Title>
        </DataTable.Header>

        <FlatList
          data={courses}
          renderItem={({ item: course }) => {
            const courseType = CourseTypes[course.courseType] || CourseTypes.General;

            return (
              <DataTable.Row style={styles.tableRow}>
                <DataTable.Cell style={styles.nameColumn}>
                  <View style={styles.nameCell}>
                    {/* {course.isDraft && (
                  <View style={styles.draftBadge}>
                    <CustomText text="Draft" size="xs" style={styles.draftText} useTranslationText={false} />
                  </View>
                )} */}
                    <CustomText text={course.name} size="s" style={styles.courseName} useTranslationText={false} />
                  </View>
                </DataTable.Cell>
                <DataTable.Cell style={styles.typeColumn}>
                  <Pill
                    text={courseType.name}
                    leftIcon={courseType.icon}
                    backgroundColor={courseType.color}
                    textColor={courseType.textColor}
                  />
                </DataTable.Cell>
                <DataTable.Cell style={styles.facultyColumn}>
                  {course.teachingFaculty.length > 0 ? (
                    <CustomText
                      text={course.teachingFaculty.map((faculty) => faculty.name).join(", ")}
                      size="s"
                      useTranslationText={false}
                      style={styles.facultyText}
                    />
                  ) : (
                    <NewCustomActionButton
                      text="Assign Faculty"
                      onPress={() => handleActionModalOpen(CoursesTabModalState.ASSIGN_FACULTY, course.id, course.name)}
                      leftIcon={null}
                    />
                  )}
                </DataTable.Cell>
                <DataTable.Cell style={styles.enrollmentColumn}>
                  <CustomText
                    text={course.enrolledStudents.length.toLocaleString()}
                    size="s"
                    style={styles.enrollmentText}
                    useTranslationText={false}
                  />
                </DataTable.Cell>
                <DataTable.Cell style={styles.actionsColumn}>
                  <CustomButton
                    text="⋮"
                    size="l"
                    styleType="primaryTransparent"
                    style={[styles.moreButton, styles.buttonBase]}
                    useTranslationText={false}
                    onPress={(e) => handleOpenMenu(e, course.id, course.name, course.teachingFaculty.length)}
                    leftIcon={null}
                    rightIcon={null}
                    textStyle={styles.buttonText}
                    disabled={false}
                    textProps={{}}
                  />
                </DataTable.Cell>
              </DataTable.Row>
            );
          }}
          keyExtractor={(item) => item.id}
        />
      </DataTable>

      <Menu
        visible={menuState.visible}
        onDismiss={handleCloseMenu}
        anchor={{ x: menuState.x, y: menuState.y }}
        contentStyle={styles.menuItem}>
        <Menu.Item
          onPress={() => handleActionModalOpen(CoursesTabModalState.DELETE_COURSE)}
          title="Delete Course"
          titleStyle={{ color: "#DC2626" }}
        />
         <Menu.Item
          onPress={() => handleActionModalOpen(CoursesTabModalState.EDIT_IMAGE)}
          title="Edit Image"
          titleStyle={{ color: "#3E68FE" }}
        />
        <Menu.Item
          disabled={menuState.courseCount === 0}
          onPress={() => handleActionModalOpen(CoursesTabModalState.REMOVE_FACULTY)}
          title="Remove Faculty"
          titleStyle={{ color: menuState.courseCount === 0 ? "#6B7280" : "#3E68FE" }}
        />
        <Menu.Item
          onPress={() => handleActionModalOpen(CoursesTabModalState.COURSE_ROSTER)}
          title="Manage Roster"
          titleStyle={{ color: "#3E68FE" }}
        />
      </Menu>
    </>
  );
};

const styles = StyleSheet.create({
  nameColumn: { flex: 5 },
  typeColumn: { flex: 2 },
  facultyColumn: { flex: 5 },
  enrollmentColumn: { flex: 2 },
  actionsColumn: { flex: 1, justifyContent: "flex-end", flexShrink: 1 },
  tableRow: {
    display: "flex",
    flexDirection: "row",
    position: "relative",
    width: "100%",
    paddingVertical: 16,
  },
  nameCell: {
    flexDirection: "row",
    alignItems: "center",
    gap: 8,
  },
  courseName: {
    color: "#111827",
    fontSize: 14,
    fontWeight: "500",
  },
  facultyText: {
    color: "#374151",
    fontSize: 14,
  },
  enrollmentText: {
    color: "#6B7280",
    fontSize: 14,
  },
  buttonBase: {
    minWidth: 32,
    height: 32,
    justifyContent: "center",
    alignItems: "center",
    padding: 0,
  },
  moreButton: {
    width: 32,
    height: 32,
    borderRadius: 4,
    backgroundColor: "transparent",
    alignItems: "center",
    justifyContent: "center",
  },
  buttonText: {
    color: "#3E68FE",
    fontSize: 14,
    fontWeight: "500",
  },
  menuItem: {
    minWidth: 150,
    backgroundColor: "#fff",
  },
});

export default CoursesTable;
