import React, { useMemo, useState, useEffect } from "react";
import { View, StyleSheet, ScrollView } from "react-native";
import CustomText from "../../common/general/CustomText/CustomText";
import useResponsiveScreen from "../../../hooks/useResponsiveScreen";
import ProgressTracker from "../ProgressTracker/ProgressTracker";
import CustomSpacing from "../../common/layout/CustomSpacing/CustomSpacing";
import Skeleton from "react-loading-skeleton";
import { useChatBar } from "../../../contexts/ChatBarContext";
import { useSuggestVideoInsightsQuery } from "../../../graphql/generated/graphql";
import CustomButton from "../../common/general/CustomButton/CustomButton";
import mediaSidebar from "../../mediaSidebar/mediaSidebar";

const ProgressLightMenu = ({ objectivesData }) => {
  const { state: chatBarState } = useChatBar();
  const { isMedium } = useResponsiveScreen();

  const [loadVideos, setLoadVideos] = useState(false);
  const [isManuallyLoading, setIsManuallyLoading] = useState(false);

  const {
    data,
    refetch,
    loading: isSuggestionsLoading,
  } = useSuggestVideoInsightsQuery({
    skip: !loadVideos,
    variables: {
      queryData: {
        contentType: chatBarState.llmMessage?.state?.school?.contentType,
        contentId: chatBarState.llmMessage?.state?.school?.contentId,
        classId: chatBarState.llmMessage?.state?.school?.classId,
        integrationId: chatBarState.llmMessage?.state?.school?.integrationId,
      },
    },
    onCompleted: () => {
      setIsManuallyLoading(false); // Reset manual loading state after query completes
    },
  });

  const media = data?.suggestVideoInsights?.data;

  const { sources, videoCardData, imageCardData } = useMemo(() => {
    if (!media) return { sources: [], videoCardData: [], imageCardData: [] };

    const sources =
      media.wiki?.map((item) => ({
        title: item.metadata.title,
        url: item.metadata.source,
      })) || [];

    const videoCardData =
      media.video?.map((item) => ({
        title: item.metadata.title,
        thumbnailUrl: item.metadata.videoThumbnail,
        url: item.metadata.source,
      })) || [];

    const imageCardData =
      media.wiki?.flatMap((item) =>
        item.metadata.images.map((imageUrl) => ({
          title: item.metadata.title,
          imageUrl: imageUrl,
        }))
      ) || [];

    return { sources, videoCardData, imageCardData };
  }, [media]);

  const handleGetVideos = () => {
    setLoadVideos(true); // Start loading videos
  };

  const handleRefreshVideos = async () => {
    setIsManuallyLoading(true); // Set manual loading state
    try {
      await refetch(); // Refetch the data
    } catch (error) {
      console.error("Error during refresh:", error);
    } finally {
      setIsManuallyLoading(false); // Ensure loading state resets
    }
  };

  const isLoading = isManuallyLoading || isSuggestionsLoading;

  return (
    <View role="menu" style={[styles.containerBase, isMedium ? styles.containerDesktop : styles.containerMobile]}>
      {objectivesData?.length === 0 && (
        <>
          <View style={styles.view}>
            <CustomText
              useTranslationText={false}
              styleType="dark"
              text="Initializing Experience..."
              size="."
              textType="text"
              weight="bold"
            />
          </View>
          <View style={styles.sidebarContainer}>
            <CustomSpacing type="vertical" size="s" />
            <Skeleton width="100%" height={175} borderRadius={8} />
            <CustomSpacing type="vertical" size="s" />
            <Skeleton width="100%" height={120} borderRadius={8} />
            <CustomSpacing type="vertical" size="s" />
            <Skeleton width="100%" height={120} borderRadius={8} />
            <CustomSpacing type="vertical" size="s" />
            <Skeleton width="100%" height={120} borderRadius={8} />
          </View>
        </>
      )}
      {objectivesData?.length > 0 && <ProgressTracker data={objectivesData} />}
      <CustomSpacing size={16} />

      <ScrollView showsVerticalScrollIndicator={false}>
        {!loadVideos ? (
          <CustomButton
            onPress={handleGetVideos}
            text="Get Video Insights"
            aria-label="Get Video Insights"
            style={styles.activeButton}
            size="s"
            styleType="primaryLightened"
          />
        ) : (
          <>
            <CustomButton
              onPress={handleRefreshVideos}
              text="Refresh Video Insights"
              aria-label="Refresh Video Insights"
              style={styles.activeButton}
              size="s"
              styleType="primaryLightened"
            />
            {isLoading ? (
              <View style={styles.sidebarContainer}>
                <CustomSpacing type="vertical" size="s" />
                <Skeleton width="100%" height={175} borderRadius={8} />
                <CustomSpacing type="vertical" size="s" />
                <Skeleton width="100%" height={120} borderRadius={8} />
                <CustomSpacing type="vertical" size="s" />
                <Skeleton width="100%" height={120} borderRadius={8} />
                <CustomSpacing type="vertical" size="s" />
                <Skeleton width="100%" height={120} borderRadius={8} />
              </View>
            ) : videoCardData.length > 0 || imageCardData.length > 0 ? (
              mediaSidebar({ sources, videoCardData, imageCardData })
            ) : (
              <>
                <CustomSpacing type="vertical" size="s" />
                <CustomText useTranslationText={false} text="No video insights available." size="xs" />
                <CustomSpacing type="vertical" size="s" />
              </>
            )}
          </>
        )}
      </ScrollView>
    </View>
  );
};

const styles = StyleSheet.create({
  sidebarContainer: {
    padding: 10,
  },
  containerBase: {
    flex: 1,
    alignSelf: "flex-start",
    overflow: "visible",
    width: 270,
    // backgroundColor: "#F9FAFB",
    paddingVertical: 32,
    paddingHorizontal: 8,
  },
  containerDesktop: {
    borderRadius: 20,
  },
  containerMobile: {
    top: 0,
    left: 0,
    borderRadius: 0,
  },
  view: {
    paddingHorizontal: 8,
    justifyContent: "flex-start",
    paddingBottom: 20,
  },
  activeButton: {
    marginLeft: 15,
    marginRight: 15,
    shadowColor: "rgba(0, 0, 0, 0.08)",
    shadowOffset: { width: 0, height: 8 },
    shadowOpacity: 1,
    shadowRadius: 16,
    elevation: 4,
    backgroundColor: "#EAECF0",
  },
});

export default ProgressLightMenu;
