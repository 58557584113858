import React from "react";
import { View, StyleSheet } from "react-native";
import CustomText from "../../components/common/general/CustomText/CustomText";
import CustomButton from "../../components/common/general/CustomButton/CustomButton";
import ActivityCardList from "../../components/customCoursework/ActivityCardList/ActivityCardList";
import { EditTaskGrey, TrashCanBlue } from "../../components/svgs/common";
import { PlusCircle } from "../../components/svgIcons";

const ModuleContainer = ({
  module,
  onEditModule,
  onDeleteModule,
  onOpenAddActivity,
  onItemOptionsPress,
  onItemToggle,
  selectedItemId,
  style,
}) => {
  const hasActivities =
    module.resources?.[0]?.aitutors?.length > 0 ||
    module.resources?.[0]?.pages?.length > 0 ||
    module.axioResources?.length > 0;
  return (
    <View style={[styles.container, style]}>
      {/* Module Header */}
      <View style={styles.moduleHeader}>
        <View style={styles.titleContainer}>
          <CustomText text={module.title} weight="bold" size="xl" useTranslationText={false} />
          <CustomButton
            leftIcon={<EditTaskGrey width={16} height={16} />}
            size="s"
            onPress={() => onEditModule(module)}
            style={{ shadowRadius: 0 }}
          />
        </View>
        <CustomButton
          leftIcon={<TrashCanBlue />}
          text="Delete"
          styleType="primaryLight"
          textStyle={{ fontSize: 14, fontWeight: 700 }}
          size="s"
          onPress={() => onDeleteModule(module)}
        />
      </View>

      {/* Activities Section */}
      <View style={styles.activitiesSection}>
        <View style={styles.activitiesHeader}>
          <CustomText text="Activities" weight="bold" size="m" />
          <CustomButton
            leftIcon={<PlusCircle width={16} height={16} />}
            text="Activity"
            size="s"
            styleType="primaryLight"
            textStyle={{ fontSize: 14, fontWeight: 700 }}
            // onPress={() => onAddActivity(module)}
            onPress={onOpenAddActivity}
          />
        </View>

        {hasActivities ? (
          <View style={{ paddingHorizontal: 20 }}>
            <ActivityCardList
              module={module}
              onItemOptionsPress={(action, item) => onItemOptionsPress(action, item)}
              onItemToggle={onItemToggle}
              selectedItemId={selectedItemId}
              style={styles.activityList}
            />
          </View>
        ) : (
          <View style={styles.noActivitiesContainer}>
            <CustomText
              text="This module doesn't have any Activities yet"
              useTranslationText={false}
              size="m"
              style={styles.noActivitiesText}
            />
          </View>
        )}
      </View>
    </View>
  );
};

const styles = StyleSheet.create({
  container: {
    flex: 1,
    backgroundColor: "#FFFFFF",
  },
  moduleHeader: {
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "center",
    paddingHorizontal: 24,
    paddingVertical: 16,
    borderBottomWidth: 1,
    borderBottomColor: "#E5E7EB",
  },
  titleContainer: {
    flexDirection: "row",
    alignItems: "center",
  },
  activitiesSection: {
    flex: 1,
  },
  activitiesHeader: {
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "center",
    paddingHorizontal: 24,
    paddingVertical: 16,
  },
  activityList: {
    flex: 1,
  },
  noActivitiesContainer: {
    flex: 1,
    justifyContent: "center",
    alignItems: "center",
    backgroundColor: "#FCFCFD",
    margin: 20,
    borderRadius: 10,
    padding: 40,
    borderWidth: 1,
    borderColor: "#EAECF0",
  },
  noActivitiesText: {
    color: "#344054",
  },
});

export default ModuleContainer;
