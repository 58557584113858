import React, { useState } from "react";
import { StyleSheet, View, TouchableOpacity } from "react-native";
import CustomText from "../../common/general/CustomText/CustomText";
import CustomButton from "../../common/general/CustomButton/CustomButton";
import { LinkBlue, DocumentBlue, ProjectBlue } from "../../svgs/CustomCoursework";
import { SparkleBlue } from "../../svgs/common";

const ChooseActivity = ({ onSelect, onCancel }) => {
  const [selectedId, setSelectedId] = useState(null);

  const activityTypes = [
    {
      id: 1,
      title: "Interactive Learning Experience",
      type: "Interactive Learning Experience",
      description:
        "Facilitate AI-driven Socratic dialogue with Axio. Provide an activity description, learning objectives, and helpful context to guide students.",
      icon: <SparkleBlue width={32} height={32} fill="#3E68FE" />,
    },
    {
      id: 2,
      title: "Resource",
      type: "Resource",
      description:
        "Share materials by uploading files like PDFs or slide decks, or link to external resources such as videos.",
      icon: <LinkBlue width={32} height={32} />,
    },
    {
      id: 3,
      title: "Assignment - Coming Soon",
      type: "Assignment",
      description:
        "Set a due date and provide instructions for student work. Optionally upload files or link videos to support the task.",
      icon: <DocumentBlue width={32} height={32} />,
    },
    {
      id: 4,
      title: "Project - Coming Soon",
      type: "Project",
      description:
        "Create a long-term activity with a due date, detailed instructions, and optional file uploads or video links.",
      icon: <ProjectBlue width={32} height={32} />,
    },
  ];

  const disabledIds = [3, 4];

  const handleCardPress = (id) => {
    if (!disabledIds.includes(id)) {
      setSelectedId(id);
    }
  };

  const handleNext = () => {
    if (selectedId) {
      const selectedType = activityTypes.find((type) => type.id === selectedId);
      onSelect(selectedType.type);
    }
  };

  return (
    <View style={styles.container}>
      <View style={styles.header}>
        <CustomText style={styles.headerText} text="Choose an Activity Type" />
      </View>
      <View style={styles.grid}>
        {activityTypes.map((type) => {
          const isDisabled = disabledIds.includes(type.id);
          return (
            <TouchableOpacity
              key={type.id}
              style={[styles.card, selectedId === type.id && styles.selectedCard, isDisabled && styles.disabledCard]}
              onPress={() => handleCardPress(type.id)}
              disabled={isDisabled}>
              <View style={styles.icon}>{type.icon}</View>
              <CustomText style={styles.title} text={type.title} useTranslationText={false} />
              <CustomText style={styles.description} text={type.description} useTranslationText={false} />
            </TouchableOpacity>
          );
        })}
      </View>
      <View style={styles.footer}>
        <CustomButton text="Cancel" size="s" styleType="primaryLight" style={styles.cancelButton} onPress={onCancel} />
        <CustomButton
          text="Next"
          size="s"
          textStyle={{ fontSize: 14, weight: 600 }}
          styleType="primary"
          onPress={handleNext}
          disabled={!selectedId}
        />
      </View>
    </View>
  );
};

const styles = StyleSheet.create({
  container: {
    backgroundColor: "#ffffff",
    borderRadius: 8,
    shadowColor: "#000",
    shadowOpacity: 0.2,
    shadowRadius: 4,
    shadowOffset: { width: 0, height: 2 },
  },
  header: {
    backgroundColor: "#F2F4F7",
    paddingVertical: 32,
    borderTopLeftRadius: 8,
    borderTopRightRadius: 8,
  },
  headerText: {
    fontSize: 20,
    fontWeight: 600,
    paddingLeft: 32,
  },
  grid: {
    flexDirection: "row",
    flexWrap: "wrap",
    justifyContent: "space-between",
    paddingHorizontal: 32,
    paddingVertical: 20,
  },
  card: {
    width: "48%",
    padding: 16,
    borderRadius: 8,
    alignItems: "flex-start",
    justifyContent: "flex-start",
    marginBottom: 16,
    borderWidth: 1,
    borderColor: "#ddd",
  },
  selectedCard: {
    borderWidth: 4,
    borderColor: "#D0D5DD",
  },
  disabledCard: {
    opacity: 0.5,
    backgroundColor: "#F6F6F6",
  },
  icon: {
    marginBottom: 8,
  },
  title: {
    fontSize: 18,
    fontWeight: 700,
    marginBottom: 8,
  },
  description: {
    fontSize: 14,
    color: "#667085",
    fontWeight: 400,
  },
  footer: {
    flexDirection: "row",
    justifyContent: "space-between",
    paddingBottom: 40,
    paddingHorizontal: 32,
  },
  cancelButton: {
    borderColor: "#3E68FE",
    borderWidth: 2,
  },
});

export default ChooseActivity;
