import { useEffect, useState } from "react";
import { MotiView } from "moti";
import { useNavigation } from "@react-navigation/native";
import CustomSpacing from "../../../components/common/layout/CustomSpacing/CustomSpacing";
import CustomText from "../../../components/common/general/CustomText/CustomText";
import { View } from "react-native";
import { ScrollView } from "react-native-gesture-handler";
import Sentry from "../../../utils/sentry";
import { useGetCourseByIdQuery } from "../../../graphql/generated/graphql";
import Skeleton from "react-loading-skeleton";
import AiTutorCardProgressList from "../../../components/lms/AiTutorCardProgressList/AiTutorCardProgressList";
import { NativeStackNavigationProp } from "@react-navigation/native-stack";
import { RootStackParamList } from "../../../navigation/AppNavigator.web";

const AiTutorCardProgressListContainer = ({ integrationId, courseId, onProgressUpdate }) => {
  const [modulesWithResources, setModulesWithResources] = useState([]);
  const navigation = useNavigation<NativeStackNavigationProp<RootStackParamList>>();

  const { data: course, loading } = useGetCourseByIdQuery({
    variables: {
      id: courseId,
    },
  });

  useEffect(() => {
    if (course && !loading) {
      const formattedModules = course?.getCourseById.data[0].modules.map((module) => {
        // Process AITutors
        const aiTutors = module.resources[0].aitutors.map((aiTutor) => {
          const aiTutorTotalObjectives =
            aiTutor.objectives && aiTutor.objectives.length > 0 ? aiTutor.objectives.length : 0;

          const aiTutorCompletedObjectives =
            aiTutor.objectives && aiTutor.objectives.length > 0
              ? aiTutor.objectives.filter(
                  (objective) => objective.userHasProgressConnection?.edges?.[0]?.properties?.status === "completed"
                ).length
              : 0;

          const isComplete = aiTutorCompletedObjectives > 0 && aiTutorCompletedObjectives === aiTutorTotalObjectives;
          const resourceDurationMinutes = (aiTutorTotalObjectives - aiTutorCompletedObjectives) * 10;

          return {
            ...aiTutor,
            resourceType: "aiTutor",
            details: {
              isComplete,
              totalObjectives: aiTutorTotalObjectives,
              completedObjectives: aiTutorCompletedObjectives,
              resourceDurationMinutes,
            },
          };
        });

        // Process axioResources
        const axioResources = module.axioResources.map((resource) => ({
          ...resource,
          resourceType: "axioResource",
          details: {
            title: resource.name,
            description: resource.description,
            videoUrl: resource.videoUrl,
          },
          files: resource.files,
        }));

        // Combine both types of resources
        const allResources = [...aiTutors, ...axioResources];

        return {
          ...module,
          resources: allResources,
        };
      });

      // Calculate progress only for AITutors
      const totalObjectivesCount = formattedModules.reduce((acc, module) => {
        return (
          acc +
          module.resources
            .filter((resource) => resource.resourceType === "aiTutor")
            .reduce((acc, aiTutor) => acc + aiTutor.details.totalObjectives, 0)
        );
      }, 0);

      const completedObjectivesCount = formattedModules.reduce((acc, module) => {
        return (
          acc +
          module.resources
            .filter((resource) => resource.resourceType === "aiTutor")
            .reduce((acc, aiTutor) => acc + aiTutor.details.completedObjectives, 0)
        );
      }, 0);

      const totalProgress = totalObjectivesCount > 0 ? (completedObjectivesCount / totalObjectivesCount) * 100 : 0;
      const totalDurationMinutes = (totalObjectivesCount - completedObjectivesCount) * 10;

      setModulesWithResources(formattedModules);
      onProgressUpdate(totalProgress, totalDurationMinutes);
    }
  }, [course]);

  const handleResourcePress = (id, resourceType) => {
    if (resourceType === "aiTutor") {
      navigation.navigate("Custom Course AITutor", {
        resource_type: "AITutor",
        resource_id: id,
        course_id: courseId,
        integration_id: integrationId,
      });
    } else {
      const resource = modulesWithResources
        .flatMap((module) => module.resources)
        .find((resource) => resource.id === id);

      if (resource) {
        const params = {
          resource_type: resourceType === "aiTutor" ? "AITutor" : "AxioResource",
          resource_id: resource.id,
          course_id: courseId,
          integration_id: integrationId,
        };

        navigation.navigate("Custom Resource Student", params);
      } else {
        Sentry.captureException(`Resource with id ${id} not found.`);
      }
    }
  };

  const handleOnHoverIn = (id) => {
    setModulesWithResources((prev) =>
      prev.map((module) => ({
        ...module,
        resources: module.resources.map((resource) =>
          resource.id === id ? { ...resource, isHovered: true } : resource
        ),
      }))
    );
  };

  const handleOnHoverOut = (id) => {
    setModulesWithResources((prev) =>
      prev.map((module) => ({
        ...module,
        resources: module.resources.map((resource) =>
          resource.id === id ? { ...resource, isHovered: false } : resource
        ),
      }))
    );
  };

  const renderContent = () => {
    if (loading) {
      return (
        <ScrollView style={{ flex: 1 }}>
          {[1, 2, 3].map((_, idx) => (
            <View key={idx}>
              <CustomSpacing type="vertical" size="l" />
              <Skeleton height={18} width={60} />
              <CustomSpacing type="vertical" size="s" />
              <Skeleton height={32} width={200} />
              <CustomSpacing type="vertical" size="l" />
              {[1, 2, 3].map((__, idx2) => (
                <Skeleton height={80} style={{ marginBottom: 10 }} key={idx2} />
              ))}
            </View>
          ))}
        </ScrollView>
      );
    }

    return (
      <ScrollView style={{ flex: 1 }}>
        {modulesWithResources.map((module) => (
          <View key={module.id}>
            <CustomSpacing type="vertical" size="l" />
            <CustomText
              text={"MODULE"}
              size="m"
              style={{ fontFamily: "Inter", fontSize: 12, lineHeight: 18, letterSpacing: "0.06", color: "#667085" }}
              useTranslationText={false}
            />
            <CustomText
              text={module.title}
              size="l"
              style={{ fontFamily: "AbrilFatface", fontSize: 24, lineHeight: 32 }}
              useTranslationText={false}
            />
            <CustomSpacing type="vertical" size="l" />
            <AiTutorCardProgressList
              items={module.resources}
              onPress={(id) => handleResourcePress(id, module.resources.find((r) => r.id === id)?.resourceType)}
              onHoverIn={handleOnHoverIn}
              onHoverOut={handleOnHoverOut}
            />
          </View>
        ))}
      </ScrollView>
    );
  };

  return (
    <MotiView
      from={{ opacity: 0, translateY: 20 }}
      animate={{ opacity: 1, translateY: 0 }}
      delay={1500}
      transition={{ type: "timing", duration: 600 }}
      style={{ flex: 1 }}
      aria-live="polite">
      {renderContent()}
    </MotiView>
  );
};

export default AiTutorCardProgressListContainer;
