import React, { useState, useEffect, useRef, useCallback } from "react";
import { useAppState, actions } from "../../../contexts/AppStateContext";
import { useAppConfig } from "../../../AppConfigProvider";
import { useFocusEffect, useRoute } from "@react-navigation/native";
import {
  SuggestVideoInsightsDocument,
  useMeQuery,
  useGenerateUploadUrlMutation,
  StoragePathType,
  useProcessAttachmentMutation,
  useGetFileQuery,
} from "../../../graphql/generated/graphql";
import ChatBar from "../../../components/navigation/ChatBar/ChatBar";
import UpgradeModalContainer from "../../general/UpgradeModalContainer/UpgradeModalContainer";
import useAudioRecorder from "../../../hooks/useAudioRecorder";
import { useChatBar } from "../../../contexts/ChatBarContext";
import AsyncStorage from "@react-native-async-storage/async-storage";
import posthog from "posthog-js";
import useWebSocket from "../../../hooks/useWebSocket";
import { useChatBarInput } from "../../../contexts/CharBarInputContext";
import Sentry from "../../../utils/sentry";
import { apolloClient } from "../../../graphql/graphql.client";
import * as DocumentPicker from "expo-document-picker";
import axios from "axios";
import { View } from "react-native";

const viewsWithoutChat = [
  "Lesson",
  "Lesson Video",
  "Lesson Assessment",
  "Lesson Activities",
  "Custom Coursework",
  "Custom Coursework Modules",
];

export const viewsWithoutChatWindow = [
  "Lesson",
  "Lesson Video",
  "Lesson Assessment",
  "Lesson Activities",
  "Lesson Chat",
  "Onboarding",
  "Custom Coursework",
  "Custom Coursework Modules",
  "Custom Course AITutor",
];

const viewsWithoutAttachments = ["Onboarding", "Custom Resource Student"];

const MAX_FILE_SIZE = 2 * 1024 * 1024; // 2MB
const ACCEPTED_MIME_TYPES = ["application/pdf"];

function ChatBarContainer({ onSubmit = () => {} }) {
  const { companionName } = useAppConfig();
  const appConfig = useAppConfig();
  const isAxioOffering = appConfig.isAxioOffering;

  const { state, handleAudioOffPress, handleAudioOnPress, dispatch } = useAppState();
  const { setShowChat, showChat, state: chatBarState, setMetaIsLoading, setSubmitEnabled } = useChatBar();
  const { setInputValue, inputValue } = useChatBarInput();
  const { sendMessage } = useWebSocket();

  const route = useRoute();

  const { data } = useMeQuery();
  const {
    data: fileData,
    startPolling,
    stopPolling,
    refetch,
  } = useGetFileQuery({ variables: { fileId: "" }, fetchPolicy: "network-only" });

  const chatInputRef = useRef(null);

  const { recording, toggleRecording } = useAudioRecorder(onSubmit);

  const [isUpgradeModalVisible, setIsUpgradeModalVisible] = useState(false);
  const [attachment, setAttachment] = useState(null);
  const [isAttachmentLoading, setIsAttachmentLoading] = useState(false);

  const [generateUploadUrl] = useGenerateUploadUrlMutation();
  const [processAttachment] = useProcessAttachmentMutation();

  const showChatWindow = !viewsWithoutChatWindow.includes(route.name);

  const isAttachmentEnabled = !viewsWithoutAttachments.includes(route.name);

  useFocusEffect(
    useCallback(() => {
      // Function to execute when the screen is focused
      const handleDocumentKeyPress = () => {
        chatInputRef.current?.focus();
      };

      if (state.meta.keypressIsListening) {
        // Add the event listener only if input is not focused and the modal is not open
        document.addEventListener("keypress", handleDocumentKeyPress);
      } else {
        // Remove the event listener if input is focused or the modal is open
        document.removeEventListener("keypress", handleDocumentKeyPress);
      }

      // Cleanup the event listener when the component is unmounted
      return () => {
        document.removeEventListener("keypress", handleDocumentKeyPress);
      };
    }, [state.meta.keypressIsListening])
  );

  useEffect(() => {
    if (fileData?.getFile?.data?.length > 0) {
      const file = fileData.getFile.data[0];
      const isLoading = file.status !== "completed";
      if (!isLoading) {
        setIsAttachmentLoading(false);
        stopPolling();
        setSubmitEnabled(true);
      }
    }
  }, [fileData, setIsAttachmentLoading, stopPolling]);

  const setAudioOn = () => {
    if (!data?.me?.isPro && state.meta.isOnboarded) {
      setIsUpgradeModalVisible(true);
    } else {
      handleAudioOnPress();
    }
  };

  const handleRecordingStopPress = () => {
    toggleRecording();
  };

  const handleRecordingStartPress = () => {
    if (!data?.me?.isPro && state.meta.isOnboarded) {
      setIsUpgradeModalVisible(true);
    } else {
      toggleRecording();
    }
  };

  const handleCloseUpgradeModals = () => {
    setIsUpgradeModalVisible(false);
  };

  const handleToggleChat = () => {
    setShowChat(!showChat);
  };

  const handleQuickOptionPress = (value) => {
    handleSubmitEditing(showChatWindow, value);
  };

  const handleSubmit = () => {
    handleSubmitEditing(showChatWindow, undefined);
    onSubmit();
  };

  const handleChangeText = (text) => {
    setInputValue(text);
  };

  const handleFileUpload = async () => {
    try {
      setIsAttachmentLoading(true);
      setSubmitEnabled(false);
      const result = await DocumentPicker.getDocumentAsync({
        type: ACCEPTED_MIME_TYPES,
        copyToCacheDirectory: true,
      });

      if (result.canceled || !result.assets || !result.assets[0]) {
        return;
      }

      const { uri, mimeType, size, name } = result.assets[0];

      // Validate file size
      if (size > MAX_FILE_SIZE) {
        throw new Error(`File size must be less than ${MAX_FILE_SIZE / (1024 * 1024)}MB`);
      }

      // Validate mime type
      if (!ACCEPTED_MIME_TYPES.includes(mimeType)) {
        throw new Error(`Invalid file type. Only PDF files are accepted.`);
      }

      const data = await generateUploadUrl({
        variables: {
          fileName: name,
          storagePath: StoragePathType.ChatAttachment,
        },
      });

      const uploadData = data?.data?.generateUploadUrl?.data ?? [];
      const { fileURL, id } = uploadData[0] || {};

      if (!fileURL) throw new Error("Failed to get signed URL");

      // Upload file to GCP using signed URL
      const fileData = await fetch(uri);
      const blob = await fileData.blob();

      await axios.put(fileURL, blob, {
        headers: {
          "Content-Type": mimeType,
        },
        onUploadProgress: (progressEvent) => {
          const percentCompleted = Math.round((progressEvent.loaded * 100) / progressEvent.total);
        },
      });

      await processAttachment({
        variables: {
          fileId: id,
        },
      });

      // Add the new file to the files array
      setAttachment({
        title: name,
        fileType: "PDF",
        id,
        status: "pending",
      });
      refetch({ fileId: id });
      startPolling(3000);
    } catch (error) {
      setIsAttachmentLoading(false);
      setSubmitEnabled(true);
      setAttachment(null);
      console.error("Error uploading file:", error);
    }
  };

  const handleDeleteAttachment = () => {
    setAttachment(null);
  };

  const handleGetSuggestionFromAxio = async () => {
    setMetaIsLoading(true);
    const result = await apolloClient.query({
      query: SuggestVideoInsightsDocument,
      variables: {
        queryData: {
          contentType: chatBarState.llmMessage?.state?.school?.contentType,
          contentId: chatBarState.llmMessage?.state?.school?.contentId,
          classId: chatBarState.llmMessage?.state?.school?.classId,
          integrationId: chatBarState.llmMessage?.state?.school?.integrationId,
        },
      },
    });
    dispatch({
      type: actions.SET_MEDIA,
      payload: result.data.suggestVideoInsights.data.media,
    });
    setMetaIsLoading(false);
  };
  const handleSubmitEditing = async (showChatWindow, quickItemValue) => {
    try {
      if (isAttachmentLoading === true) return;

      posthog?.capture("message sent to axio");
      setAttachment(null);

      if (state.meta.isAdventure) {
        dispatch({
          type: actions.SET_META,
          payload: { isAdventure: false },
        });
      }

      await sendMessageToAxio(quickItemValue);

      if (showChatWindow) {
        setShowChat(true);
      }

      setInputValue("");
    } catch (error) {
      Sentry.captureException(error);
    }
  };

  const sendMessageToAxio = async (quickItemValue) => {
    const token = await AsyncStorage.getItem("token");

    setMetaIsLoading(true);
    sendMessage({
      ...chatBarState.llmMessage,
      meta: {
        ...chatBarState.llmMessage.meta,
        token,
        isLoading: true,
        attachmentId: attachment ? attachment.id : false,
      },
      content: { ...chatBarState.llmMessage.content, message: quickItemValue || inputValue },
      state: {
        ...chatBarState.llmMessage.state,
      },
    });
  };

  if (viewsWithoutChat.includes(route.name)) {
    return null;
  }

  return (
    <View
      style={{
        width: "100%",
        marginHorizontal: "auto",
        display: "flex",
        justifyContent: "center",
        flexDirection: "row",
      }}>
      <ChatBar
        value={inputValue}
        isRecording={recording}
        isAudioOn={state.meta.isAudioOn}
        placeholder={recording ? `${companionName} is listening...` : `Chat with ${companionName}...`}
        inputRef={chatInputRef}
        onChangeText={handleChangeText}
        onSubmitEditing={handleSubmit}
        onAudioOffPress={handleAudioOffPress}
        onAudioOnPress={setAudioOn}
        onRecordingStopPress={handleRecordingStopPress}
        onRecordingStartPress={handleRecordingStartPress}
        onToggleChatPress={handleToggleChat}
        onQuickOptionPress={handleQuickOptionPress}
        onAttachmentPress={handleFileUpload}
        onDeleteAttachmentPress={handleDeleteAttachment}
        onGetSuggestionsPress={handleGetSuggestionFromAxio}
        isCustomOffering={isAxioOffering}
        showQuickOptions={true}
        showChatWindow={showChatWindow}
        showChat={showChat}
        isSubmitEnabled={chatBarState.isSubmitEnabled}
        isAttachmentEnabled={isAttachmentEnabled}
        attachment={attachment}
        isAttachmentLoading={isAttachmentLoading}
      />

      <UpgradeModalContainer visible={isUpgradeModalVisible} onClose={handleCloseUpgradeModals} />
    </View>
  );
}

export default ChatBarContainer;
