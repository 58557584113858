import React, { useEffect, useState, useCallback } from "react";
import { View, StyleSheet, Linking, Platform, Easing } from "react-native";
import CustomText from "../components/common/general/CustomText/CustomText";
import CustomSpacing from "../components/common/layout/CustomSpacing/CustomSpacing";
import AsyncStorage from "@react-native-async-storage/async-storage";
import BackButton from "../components/navigation/BackButton/BackButton";
import { useNavigation, useRoute, useFocusEffect, RouteProp } from "@react-navigation/native";
import { MotiView } from "moti";
import { searchWeb } from "../services";
import FileCard from "../components/lms/FileCard/FileCard";
import useResponsiveScreen from "../hooks/useResponsiveScreen";
import CustomHtml from "../components/common/general/CustomHtml/CustomHtml";
import SourcesList from "../components/lms/SourcesList/SourcesList";
import ImageCardList from "../components/lms/ImageCardList/ImageCardList";
import VideoCardList from "../components/lms/VideoCardList/VideoCardList";
import Divider from "../components/common/layout/Divider/Divider";
import Loader from "../components/common/status/Loader/Loader";
import { useAppConfig } from "../AppConfigProvider";
import Sentry from "../utils/sentry";
import { useGetAxioResourceQuery } from "../graphql/generated/graphql";
import Skeleton from "react-loading-skeleton";
import { useAppState } from "../contexts/AppStateContext";
import NoChatBarLayout from "../layouts/NoChatBarLayout.web";
import ActivityInstructionsModal from "../components/lms/ActivityInstructionsModal/ActivityInstructionsModal";
import { RootStackParamList } from "../navigation/AppNavigator.web";
import { NativeStackNavigationProp } from "@react-navigation/native-stack";
import PDFViewer from "../components/lms/PDFViewer/PDFViewer";
import { WebView } from "react-native-webview";
import YoutubeVideoPlayer from "../components/youtube/YoutubeVideoPlayer";

function CustomResourceStudentView() {
  const [isLoadingSidebarData, setIsLoadingSidebarData] = useState(true);
  const [showVideo, setShowVideo] = useState(true);
  const navigation = useNavigation<NativeStackNavigationProp<RootStackParamList>>();
  const route = useRoute<RouteProp<RootStackParamList, "Custom Resource Student">>();
  const {
    resource_type: resourceType,
    resource_id: resourceId,
    course_id: courseId,
    integration_id: integrationId,
  } = route.params;

  const { isMedium } = useResponsiveScreen();
  const [isModalVisible, setIsModalVisible] = useState(false);

  const appConfig = useAppConfig();
  const isSuggestedInsights = appConfig.isSuggestedInsights;

  const [videoCardData, setVideoCardData] = useState([]);
  const [sources, setSources] = useState([]);
  const [imageCardData, setImageCardData] = useState([]);
  const { dispatch } = useAppState();

  const {
    data: axioResourceData,
    loading: loadingAxioResource,
    error: axioResourceError,
  } = useGetAxioResourceQuery({
    variables: { id: resourceId },
    skip: resourceType !== "AxioResource",
  });

  const [selectedFile, setSelectedFile] = useState(null);

  useFocusEffect(
    useCallback(() => {
      // Reset media state when the component is focused
      dispatch({
        type: "SET_MEDIA",
        payload: {
          video: null,
          wiki: null,
        },
      });
      setShowVideo(true);

      return () => {
        setSelectedFile(null);
        setShowVideo(false);
        dispatch({
          type: "SET_MEDIA",
          payload: {
            video: null,
            wiki: null,
          },
        });
      };
    }, [dispatch])
  );

  useEffect(() => {
    if (resourceType === "AxioResource" && axioResourceData?.getAxioResource?.data?.[0].name) {
      const fetchData = async () => {
        try {
          setIsLoadingSidebarData(true);
          const token = await AsyncStorage.getItem("token");
          const data = await searchWeb(axioResourceData.getAxioResource.data?.[0].id, token);

          const videos = data.video.map((item) => ({
            imageUrl: item.metadata.videoThumbnail,
            title: item.metadata.title,
            url: item.metadata.source,
          }));

          const images = data.images.flatMap((item) =>
            item.metadata.images.slice(0, 1).map((image) => ({
              imageUrl: image,
              title: item.metadata.title,
              source: item.metadata.source,
            }))
          );

          const wikipedia = data.Wikipedia.map((item) => ({
            content: item.pageContent,
            title: item.metadata.title,
            source: item.metadata.source,
          }));

          setVideoCardData(videos);
          setImageCardData(images);
          setSources(wikipedia);
          setIsLoadingSidebarData(false);
        } catch (error) {
          Sentry.captureException(error);
          setIsLoadingSidebarData(false);
        }
      };

      fetchData();
    }
  }, [axioResourceData, resourceType]);

  const handleBackPress = () => {
    navigation.navigate("Custom Course Student", {
      course_id: courseId,
      integration_id: integrationId,
    });
  };

  const renderFiles = (files) => {
    if (!files || files.length === 0) return null;

    const isPDFFile = async (url) => {
      try {
        const response = await fetch(url, { method: "HEAD" });
        const contentType = response.headers.get("content-type");
        return contentType?.toLowerCase().includes("pdf");
      } catch (error) {
        console.error("Error checking file type:", error);
        return false;
      }
    };

    const handleFileClick = async (file) => {
      try {
        // const isPDF = await isPDFFile(file.fileURL);
        handleOpenURL(file.fileURL);
        // if (isPDF && Platform.OS === "web") {
        //   setSelectedFile(file);
        // } else {
        //   handleOpenURL(file.fileURL);
        // }
      } catch (error) {
        console.error("Error handling file click:", error);
        handleOpenURL(file.fileURL);
      }
    };

    return (
      <>
        <CustomText text="Files" size="m" weight="bold" />
        <CustomSpacing type="vertical" size="s" />
        <View style={styles.filesContainer}>
          {files.map((file) => (
            <FileCard
              key={file.fileId || file.id}
              title={file.fileName}
              url={file.fileURL}
              onPress={() => handleFileClick(file)}
            />
          ))}
        </View>
        {selectedFile && Platform.OS === "web" && (
          <>
            <CustomSpacing type="vertical" size="l" />
            <View style={styles.previewContainer}>
              <PDFViewer url={selectedFile.fileURL} />
            </View>
          </>
        )}
        <CustomSpacing type="vertical" size="xl" />
      </>
    );
  };

  const renderVideo = () => {
    const videoUrl = axioResourceData.getAxioResource.data?.[0].videoUrl;
    const youtubeVideoId = extractYoutubeVideoId(videoUrl);
    if (youtubeVideoId && showVideo) {
      return <YoutubeVideoPlayer id={youtubeVideoId} />;
    }

    return (
      <CustomText
        text={videoUrl}
        useTranslationText={false}
        style={styles.linkText}
        onPress={() => handleOpenURL(videoUrl)}
      />
    );
  };

  const renderSidebar = () => {
    const hasData = sources.length > 0 || imageCardData.length > 0 || videoCardData.length > 0;

    if (!hasData) {
      return null;
    }

    return (
      <MotiView
        from={{ opacity: 0, translateY: 20, scale: 1 }}
        animate={{ opacity: 1, translateY: 0, scale: 1 }}
        delay={10}
        style={styles.sidebarContainer}
        transition={{ type: "timing", duration: 600 }}>
        {sources.length > 0 && (
          <>
            <CustomText text="Sources" style={styles.headerText} size="m" weight="bold" role="heading" aria-level="2" />
            <SourcesList sources={sources} />
            <CustomSpacing type="vertical" size="m" />
          </>
        )}

        {imageCardData.length > 0 && (
          <>
            <CustomText text="Images" style={styles.headerText} size="m" weight="bold" role="heading" aria-level="2" />
            <ImageCardList imageCardData={imageCardData} />
            <CustomSpacing type="vertical" size="m" />
          </>
        )}

        {videoCardData.length > 0 && (
          <>
            <CustomText text="Videos" style={styles.headerText} size="m" weight="bold" role="heading" aria-level="2" />
            <VideoCardList videoCardData={videoCardData} />
            <CustomSpacing type="vertical" size="m" />
          </>
        )}
      </MotiView>
    );
  };

  const renderSkeletonLoader = () => (
    <View style={styles.skeletonContainer}>
      <View style={styles.detailsContainer}>
        {/* Header Section */}
        <View style={styles.headerContainer}>
          <Skeleton width={40} height={40} circle={true} />
          <CustomSpacing type="horizontal" size="m" />
          <Skeleton width="60%" height={24} />
        </View>

        <CustomSpacing type="vertical" size="m" />

        <CustomSpacing type="vertical" size="l" />

        {/* Button Section */}
        <Skeleton width="30%" height={40} borderRadius={8} />

        <CustomSpacing type="vertical" size="l" />

        {/* Suggested Insights Section */}
        <Skeleton width="100%" height={200} borderRadius={12} />
      </View>

      {/* Sidebar Section */}
      <View style={styles.sidebarContainer}>
        <Skeleton width="80%" height={24} />
        <CustomSpacing type="vertical" size="s" />
        <Skeleton width="100%" height={150} borderRadius={8} />
      </View>
    </View>
  );

  const handleOpenURL = (url) => {
    if (Platform.OS === "web") {
      const win = window.open(url, "_blank");
      if (win) win.focus();
    } else {
      Linking.openURL(url).catch((err) => {
        console.error("Failed to open URL:", err);
        Sentry.captureException(err);
      });
    }
  };

  const extractYoutubeVideoId = (url: string): string | null => {
    const patterns = [
      /(?:youtube\.com\/watch\?v=|youtu\.be\/|youtube\.com\/embed\/)([^&\n?#]+)/,
      /^[a-zA-Z0-9_-]{11}$/,
    ];

    for (const pattern of patterns) {
      const match = url.match(pattern);
      if (match && match[1]) {
        return match[1];
      }
    }
    return null;
  };

  if (axioResourceError) {
    return (
      <View style={styles.errorContainer}>
        <CustomText text="Failed to load Axio Resource data." color="red" />
      </View>
    );
  }

  return (
    <NoChatBarLayout>
      <ActivityInstructionsModal visible={isModalVisible} onClose={() => setIsModalVisible(false)} />
      {loadingAxioResource ? (
        renderSkeletonLoader()
      ) : (
        <View style={isMedium ? styles.mainContainer : styles.mainContainerMobile}>
          <View style={isMedium ? styles.rowContainer : styles.columnContainer}>
            <View style={isMedium ? styles.contentContainer : styles.fullWidthContentContainer}>
              {/* Header Section */}
              <MotiView
                from={{
                  opacity: 0,
                  translateY: 20,
                  scale: 1,
                }}
                animate={{
                  opacity: 1,
                  translateY: 0,
                  scale: 1,
                }}
                delay={10}
                transition={{ type: "timing", duration: 600 }}>
                <View style={styles.headerContainer}>
                  <BackButton onPress={handleBackPress} />
                  <CustomText
                    useTranslationText={false}
                    text={axioResourceData?.getAxioResource?.data?.[0].name}
                    size={isMedium ? "xs" : "m"}
                    weight={isMedium ? "medium" : "bold"}
                  />
                </View>
                <Divider type="vertical" />
              </MotiView>

              <MotiView
                from={{
                  opacity: 0,
                  translateY: 20,
                  scale: 1,
                }}
                animate={{
                  opacity: 1,
                  translateY: 0,
                  scale: 1,
                }}
                delay={600}
                transition={{ type: "timing", duration: 600 }}
                style={{ paddingHorizontal: 30, paddingVertical: 10 }}>
                <CustomText text="Description" size="m" weight="bold" />
                <CustomSpacing type="vertical" size="s" />
                <CustomHtml
                  html={axioResourceData.getAxioResource.data?.[0].description || "<p>No description available</p>"}
                />

                <CustomSpacing type="vertical" size="xl" />

                {/* Files Section */}
                {renderFiles(axioResourceData.getAxioResource.data?.[0].files)}

                {/* Video URL Section */}
                {axioResourceData.getAxioResource.data?.[0].videoUrl && (
                  <>
                    <CustomText text="Video" size="m" weight="bold" />
                    <CustomSpacing type="vertical" size="s" />
                    {renderVideo()}
                    <CustomSpacing type="vertical" size="xl" />
                  </>
                )}
              </MotiView>

              <CustomSpacing type="vertical" size="l" />
            </View>

            {/* Sidebar Section */}
            {isSuggestedInsights && (
              <>
                {isMedium ? (
                  <View style={[{ width: 300 }, isLoadingSidebarData ? { alignItems: "center" } : {}]}>
                    <CustomText
                      style={{ margin: 12 }}
                      textType="display"
                      text="Suggested Insights"
                      size="xs"
                      bold={false}
                      role="heading"
                      aria-level="1"
                    />
                    {isLoadingSidebarData ? (
                      <View style={styles.insightsCenterView}>
                        <Loader />
                      </View>
                    ) : (
                      <>{renderSidebar()}</>
                    )}
                  </View>
                ) : (
                  <>
                    <Divider size="l" />
                    <CustomText
                      textType="display"
                      text="Suggested Insights"
                      size="xs"
                      bold={true}
                      weight="bold"
                      role="heading"
                      aria-level="1"
                    />
                    <View style={isLoadingSidebarData ? { alignItems: "center" } : {}}>
                      {isLoadingSidebarData ? (
                        <View style={styles.fullWidthContentContainer}>
                          <Loader />
                        </View>
                      ) : (
                        <>{renderSidebar()}</>
                      )}
                    </View>
                  </>
                )}
              </>
            )}
          </View>
        </View>
      )}
    </NoChatBarLayout>
  );
}

const styles = StyleSheet.create({
  skeletonContainer: {
    flex: 1,
    flexDirection: "row", // Reflects the row layout in the loaded UI
    justifyContent: "space-between",
  },
  // Details container for central content
  detailsContainer: {
    flex: 3, // Takes 3/4 of the width for the main content
    paddingRight: 16,
  },
  mainContainerMobile: {
    height: 20,
  },
  mainContainer: {
    // flex: 1,
    height: 20,
  },
  rowContainer: {
    flexDirection: "row",
    flex: 1,
  },
  columnContainer: {
    flexDirection: "column",
    flex: 1,
  },
  fullWidthContentContainer: {
    padding: 16,
    // height: 20,
  },
  contentContainer: {
    flex: 3, // Takes 3/4 of the width
    padding: 16,
  },
  headerText: {
    marginBottom: 6,
  },
  sidebarContainer: {
    flex: 1, // Takes 1/4 of the width
    justifyContent: "flex-start",
    paddingBottom: 16,
    paddingLeft: 16,
    paddingRight: 16,
  },
  headerContainer: {
    flexDirection: "row",
    justifyContent: "flex-start",
    alignItems: "center",
    alignContent: "center",
  },
  insightsCenterView: {
    width: "100%",
    justifyContent: "center",
    alignItems: "center",
  },
  filesContainer: {
    flexDirection: "column",
    gap: 10,
    maxWidth: 320,
  },
  previewContainer: {
    minHeight: 600,
    borderWidth: 1,
    borderColor: "#D9D9D9",
    borderRadius: 10,
    overflow: "hidden",
    backgroundColor: "#fff",
  },
  linkText: {
    color: "blue",
    textDecorationLine: "underline",
    textAlign: "left",
    borderWidth: 0,
  },
  // Error Container for Error Handling
  errorContainer: {
    flex: 1,
    justifyContent: "center",
    alignItems: "center",
  },
});

export default CustomResourceStudentView;
