import React, { useState, useEffect, useRef } from "react";
import {
  View,
  StyleSheet,
  Text,
  TextInput,
  Image,
  TouchableOpacity,
  LayoutAnimation,
  UIManager,
  Platform,
} from "react-native";
import NoChatBarLayout from "../layouts/NoChatBarLayout.web";
import CustomText from "../components/common/general/CustomText/CustomText";
import CustomSpacing from "../components/common/layout/CustomSpacing/CustomSpacing";
import { MotiView } from "moti";
import ClockImage from "../assets/Clock.png";
import { ThumbUp } from "../components/svgs/common";

function FeedbackCard({ title, description, highlights }) {
  return (
    <View style={styles.card}>
      <View style={styles.iconContainer}>
        <ThumbUp flip={true} color="#FFFFFF" style={{ width: 24, height: 24 }} />
      </View>

      <CustomText useTranslationText={false} textType="text" text={title} size="xl" style={styles.title} />
      <CustomText useTranslationText={false} textType="text" text={description} size="s" style={styles.description} />
      <View style={styles.highlightsContainer}>
        {highlights.map((highlight, index) => {
          const [boldText, normalText] = highlight.split(": ");
          return (
            <View key={index} style={styles.highlightBox}>
              <Text style={styles.text}>
                <CustomText
                  useTranslationText={false}
                  textType="text"
                  text={boldText + ":"}
                  size="s"
                  style={styles.boldText}
                />{" "}
                <CustomText useTranslationText={false} textType="text" text={normalText} size="s" style={styles.text} />
              </Text>
            </View>
          );
        })}
      </View>
    </View>
  );
}

const InsightsView = () => {
  const [expandedClass, setExpandedClass] = useState(null);
  const [expandedModule, setExpandedModule] = useState(null);

  const toggleClassExpand = (classId) => {
    LayoutAnimation.configureNext(LayoutAnimation.Presets.easeInEaseOut);
    setExpandedClass(expandedClass === classId ? null : classId);
  };

  const toggleModuleExpand = (moduleId) => {
    LayoutAnimation.configureNext(LayoutAnimation.Presets.easeInEaseOut);
    setExpandedModule(expandedModule === moduleId ? null : moduleId);
  };

  // Dummy classes
  const classes = [
    {
      id: 1,
      name: "Introduction to Statistics",
      completion: "60%",
      timeSpent: "28 hrs",
      modules: [
        {
          id: 1,
          name: "Module 1: Basics of Probability",
          completion: "60%",
          timeSpent: "10 hrs",
          details: [
            {
              title: "What you did well",
              description:
                "You have a strong understanding of probability rules, including conditional probability and Bayes' theorem. You quickly grasp foundational concepts and successfully solve most practice problems.",
            },
            {
              title: "Where you can put more focus",
              description:
                "You can improve on understanding randomness, probability calculations, and decision-making strategies. Pay extra attention to differentiating between random sampling and stratified sampling.",
            },
          ],

          activities: [
            {
              category: "well",
              name: "Understanding Probability Basics",
              description: "You quickly grasped foundational concepts and successfully solved most practice problems.",
              timeSpent: "3 hrs",
            },
            {
              category: "well",
              name: "Real-Life Probability Scenarios",
              description:
                "You demonstrated creative thinking by applying probability concepts to real-world examples.",
              timeSpent: "4 hrs",
            },
            {
              category: "focus",
              name: "Calculating Probability Distributions",
              description:
                "You struggled with random sampling concepts, especially in differentiating it from stratified sampling. Your answers sometimes lacked originality.",
              timeSpent: "6 hrs",
            },
            {
              category: "focus",
              name: "Probability Word Problems",
              description:
                "You can improve on identifying bias in data collection methods. Consider reviewing examples of biased surveys.",
              timeSpent: "3 hrs",
            },
          ],
        },
        {
          id: 2,
          name: "Module 2: Fundamentals of Statistics",
          completion: "75%",
          timeSpent: "18 hrs",
          details: [
            {
              title: "What you did well",
              description:
                "You have a strong understanding of probability rules, including conditional probability and Bayes' theorem. You quickly grasp foundational concepts and successfully solve most practice problems.",
            },
            {
              title: "Where you can put more focus",
              description:
                "You can improve on understanding randomness, probability calculations, and decision-making strategies. Pay extra attention to differentiating between random sampling and stratified sampling.",
            },
          ],

          activities: [
            {
              category: "well",
              name: "Understanding Probability Basics",
              description: "You quickly grasped foundational concepts and successfully solved most practice problems.",
              timeSpent: "3 hrs",
            },
            {
              category: "well",
              name: "Real-Life Probability Scenarios",
              description:
                "You demonstrated creative thinking by applying probability concepts to real-world examples.",
              timeSpent: "4 hrs",
            },
            {
              category: "focus",
              name: "Calculating Probability Distributions",
              description:
                "You struggled with random sampling concepts, especially in differentiating it from stratified sampling. Your answers sometimes lacked originality.",
              timeSpent: "6 hrs",
            },
            {
              category: "focus",
              name: "Probability Word Problems",
              description:
                "You can improve on identifying bias in data collection methods. Consider reviewing examples of biased surveys.",
              timeSpent: "3 hrs",
            },
          ],
        },
      ],
    },
    {
      id: 2,
      name: "Advanced Data Analysis",
      completion: "50%",
      timeSpent: "35 hrs",
      modules: [
        {
          id: 3,
          name: "Module 1: Regression Analysis",
          completion: "50%",
          timeSpent: "15 hrs",
          details: [
            {
              title: "What you did well",
              description:
                "You have a strong understanding of probability rules, including conditional probability and Bayes' theorem. You quickly grasp foundational concepts and successfully solve most practice problems.",
            },
            {
              title: "Where you can put more focus",
              description:
                "You can improve on understanding randomness, probability calculations, and decision-making strategies. Pay extra attention to differentiating between random sampling and stratified sampling.",
            },
          ],

          activities: [
            {
              category: "well",
              name: "Understanding Probability Basics",
              description: "You quickly grasped foundational concepts and successfully solved most practice problems.",
              timeSpent: "3 hrs",
            },
            {
              category: "well",
              name: "Real-Life Probability Scenarios",
              description:
                "You demonstrated creative thinking by applying probability concepts to real-world examples.",
              timeSpent: "4 hrs",
            },
            {
              category: "focus",
              name: "Calculating Probability Distributions",
              description:
                "You struggled with random sampling concepts, especially in differentiating it from stratified sampling. Your answers sometimes lacked originality.",
              timeSpent: "6 hrs",
            },
            {
              category: "focus",
              name: "Probability Word Problems",
              description:
                "You can improve on identifying bias in data collection methods. Consider reviewing examples of biased surveys.",
              timeSpent: "3 hrs",
            },
          ],
        },
        {
          id: 4,
          name: "Module 2: Hypothesis Testing",
          completion: "60%",
          timeSpent: "20 hrs",
          details: [
            {
              title: "What you did well",
              description:
                "You have a strong understanding of probability rules, including conditional probability and Bayes' theorem. You quickly grasp foundational concepts and successfully solve most practice problems.",
            },
            {
              title: "Where you can put more focus",
              description:
                "You can improve on understanding randomness, probability calculations, and decision-making strategies. Pay extra attention to differentiating between random sampling and stratified sampling.",
            },
          ],

          activities: [
            {
              category: "well",
              name: "Understanding Probability Basics",
              description: "You quickly grasped foundational concepts and successfully solved most practice problems.",
              timeSpent: "3 hrs",
            },
            {
              category: "well",
              name: "Real-Life Probability Scenarios",
              description:
                "You demonstrated creative thinking by applying probability concepts to real-world examples.",
              timeSpent: "4 hrs",
            },
            {
              category: "focus",
              name: "Calculating Probability Distributions",
              description:
                "You struggled with random sampling concepts, especially in differentiating it from stratified sampling. Your answers sometimes lacked originality.",
              timeSpent: "6 hrs",
            },
            {
              category: "focus",
              name: "Probability Word Problems",
              description:
                "You can improve on identifying bias in data collection methods. Consider reviewing examples of biased surveys.",
              timeSpent: "3 hrs",
            },
          ],
        },
      ],
    },
  ];
  const feedbackData = [
    {
      title: "What You Are Doing Well",
      description:
        "You’re doing a great job coming up with original questions, showcasing creative thinking, and quickly grasping challenging concepts.",
      highlights: [
        "Excelling in Statistical Methods: Your unique approach to solving probability problems stands out.",
        "Great work in Fundamentals of Programming: Your engagement is high, and you’re asking insightful questions about debugging loops.",
        "Fantastic effort in Data Visualization Basics: You’re effectively creating clear and impactful charts.",
      ],
    },
    {
      title: "Areas for Improvement",
      description:
        "Consider refining your explanations and reviewing complex problem-solving approaches to enhance clarity.",
      highlights: [
        "Improve explanations in Probability Theory",
        "Strengthen debugging skills in JavaScript",
        "Enhance clarity in Data Visualization storytelling",
      ],
    },
    {
      title: "Keep Up the Good Work!",
      description: "Your enthusiasm and dedication to learning are truly commendable!",
      highlights: [
        "Maintaining a strong work ethic",
        "Active participation in discussions",
        "Consistently demonstrating problem-solving skills",
      ],
    },
  ];

  return (
    <NoChatBarLayout>
      <MotiView
        from={{ opacity: 0, translateY: 20 }}
        animate={{ opacity: 1, translateY: 0 }}
        delay={10}
        transition={{ type: "timing", duration: 500 }}>
        <CustomText useTranslationText={false} textType="display" text="Insights" size="m" weight="ultraBold" />
        <CustomText
          useTranslationText={false}
          textType="text"
          text="Your Learning Snapshot"
          size="xl"
          style={{ marginTop: 48 }}
        />
      </MotiView>
      <MotiView
        from={{ opacity: 0, translateY: 20 }}
        animate={{ opacity: 1, translateY: 0 }}
        delay={10}
        transition={{ type: "timing", duration: 500 }}>
        <View
          style={{
            position: "relative",
            flexDirection: "row",
            alignItems: "center",
            padding: 20,
            justifyContent: "space-between",
            marginTop: 24,
            flex: 1,
            borderRadius: 20,
            borderWidth: 1,
            borderColor: "#EAECF0",
            backgroundColor: "#FFF",
            overflow: "hidden",
          }}>
          <View style={{ flex: 1, gap: 16 }}>
            <CustomText
              useTranslationText={false}
              textType="text"
              text="Total Engagement"
              size="s"
              style={{
                color: "#101828",
                letterSpacing: 1.26,
                textTransform: "uppercase",
              }}
            />
            <View style={{ gap: 8, alignSelf: "stretch" }}>
              <CustomText
                useTranslationText={false}
                textType="text"
                text="Total Time Spent"
                size="xxl"
                style={{ color: "#667085" }}
              />
              <CustomText
                useTranslationText={false}
                textType="text"
                text="214 Hours"
                weight="ultraBold"
                style={{
                  paddingTop: 8,
                  fontSize: 36,
                  color: "#101828",
                }}
              />
            </View>

            <View>
              <View style={{ flexDirection: "row", gap: 3 }}>
                <CustomText
                  useTranslationText={false}
                  textType="text"
                  text="Active sessions:"
                  size="m"
                  style={{ color: "#667085" }}
                />
                <CustomText
                  useTranslationText={false}
                  size="m"
                  text="12"
                  weight="ultraBold"
                  style={{ color: "#667085" }}
                />
              </View>
              <View style={{ flexDirection: "row", gap: 3 }}>
                <CustomText
                  useTranslationText={false}
                  textType="text"
                  text="Questions asked:"
                  size="m"
                  style={{ color: "#667085" }}
                />
                <CustomText
                  useTranslationText={false}
                  size="m"
                  text="48"
                  weight="ultraBold"
                  style={{ color: "#667085" }}
                />
              </View>
            </View>
          </View>

          <View
            style={{
              margin: 17,
              position: "absolute",
              right: 0,
              bottom: 0,
              width: 175,
              height: 181,
            }}>
            <Image source={ClockImage} style={{ width: 180, height: 181 }} />

            <View
              style={{
                position: "absolute",
                top: -85,
                left: -85,
                width: 350,
                height: 350,
                borderRadius: 200,
                borderWidth: 85,
                borderColor: "#EAECF0",
              }}
            />
          </View>
        </View>
      </MotiView>
      <CustomText
        useTranslationText={false}
        textType="text"
        text="Areas of Focus"
        size="xl"
        style={{ marginTop: 48, marginBottom: 24 }}
      />
      <MotiView style={styles.container}>
        {feedbackData.map((item, index) => (
          <FeedbackCard key={index} title={item.title} description={item.description} highlights={item.highlights} />
        ))}
      </MotiView>
      <CustomText
        useTranslationText={false}
        textType="text"
        text="Class Breakdown"
        size="xl"
        style={{ marginTop: 48, marginBottom: 24 }}
      />
      <MotiView style={styles.classListContainer}>
        {classes.map((classItem) => (
          <View key={classItem.id} style={styles.classContainer}>
            <TouchableOpacity onPress={() => toggleClassExpand(classItem.id)} style={styles.classHeader}>
              <CustomText useTranslationText={false} textType="text" text={classItem.name} size="xl" weight="normal" />
              <View style={styles.statsContainer}>
                <View style={styles.statBox}>
                  <CustomText
                    useTranslationText={false}
                    textType="text"
                    weight="ultraBold"
                    text={classItem.completion}
                    size="xl"
                  />
                  <CustomText
                    useTranslationText={false}
                    textType="text"
                    text="completed"
                    size="xs"
                    style={styles.statLabel}
                  />
                </View>
                <View style={styles.statBox}>
                  <CustomText
                    useTranslationText={false}
                    textType="text"
                    weight="ultraBold"
                    text={classItem.timeSpent}
                    size="xl"
                  />
                  <CustomText
                    useTranslationText={false}
                    textType="text"
                    text="time spent"
                    size="xs"
                    style={styles.statLabel}
                  />
                </View>
              </View>
            </TouchableOpacity>

            {expandedClass === classItem.id && (
              <View style={styles.moduleList}>
                {classItem.modules.map((module) => (
                  <View key={module.id} style={styles.moduleContainer}>
                    <TouchableOpacity onPress={() => toggleModuleExpand(module.id)} style={styles.moduleHeader}>
                      <CustomText
                        useTranslationText={false}
                        textType="text"
                        text={module.name}
                        size="m"
                        weight="ultraBold"
                      />
                      <View style={styles.statsContainer}>
                        <View style={styles.statBox}>
                          <CustomText
                            useTranslationText={false}
                            textType="text"
                            weight="ultraBold"
                            text={module.completion}
                            size="m"
                          />
                          <CustomText
                            useTranslationText={false}
                            textType="text"
                            text="completed"
                            size="xs"
                            style={styles.statLabel}
                          />
                        </View>
                        <View style={styles.statBox}>
                          <CustomText
                            useTranslationText={false}
                            textType="text"
                            weight="ultraBold"
                            text={module.timeSpent}
                            size="m"
                          />
                          <CustomText
                            useTranslationText={false}
                            textType="text"
                            text="time spent"
                            size="xs"
                            style={styles.statLabel}
                          />
                        </View>
                      </View>
                    </TouchableOpacity>

                    {expandedModule === module.id && (
                      <View style={styles.detailsContainer}>
                        <View style={styles.detailColumns}>
                          <View style={styles.detailBox}>
                            <View style={styles.greenBackground}>
                              <CustomText
                                useTranslationText={false}
                                textType="text"
                                weight="ultraBold"
                                text="What you did well"
                                size="m"
                              />
                            </View>
                            {module.details
                              .filter((detail) => detail.title === "What you did well")
                              .map((detail, index) => (
                                <CustomText
                                  key={index}
                                  useTranslationText={false}
                                  textType="text"
                                  text={detail.description}
                                  size="s"
                                  style={styles.detailText}
                                />
                              ))}

                            {module.activities
                              ?.filter((activity) => activity.category === "well")
                              .map((activity, index) => (
                                <View key={index} style={styles.activityRow}>
                                  <View style={styles.activityTextContainer}>
                                    <CustomText
                                      useTranslationText={false}
                                      textType="text"
                                      weight="ultraBold"
                                      text={`Activity: ${activity.name}`}
                                      size="m"
                                    />
                                    <CustomText
                                      useTranslationText={false}
                                      textType="text"
                                      text={activity.description}
                                      size="s"
                                      style={styles.detailText}
                                    />
                                  </View>
                                  <View style={styles.statBox}>
                                    <CustomText
                                      useTranslationText={false}
                                      textType="text"
                                      weight="ultraBold"
                                      text={activity.timeSpent}
                                      size="s"
                                    />
                                    <CustomText
                                      useTranslationText={false}
                                      textType="text"
                                      text="time spent"
                                      size="xs"
                                      style={styles.statLabel}
                                    />
                                  </View>
                                </View>
                              ))}
                          </View>

                          <View style={styles.detailBox}>
                            <View style={styles.redBackground}>
                              <CustomText
                                useTranslationText={false}
                                textType="text"
                                weight="ultraBold"
                                text="Where you can put more focus"
                                size="m"
                              />
                            </View>
                            {module.details
                              .filter((detail) => detail.title === "Where you can put more focus")
                              .map((detail, index) => (
                                <CustomText
                                  key={index}
                                  useTranslationText={false}
                                  textType="text"
                                  text={detail.description}
                                  size="s"
                                  style={styles.detailText}
                                />
                              ))}

                            {module.activities
                              ?.filter((activity) => activity.category === "focus")
                              .map((activity, index) => (
                                <View key={index} style={styles.activityRow}>
                                  <View style={styles.activityTextContainer}>
                                    <CustomText
                                      useTranslationText={false}
                                      textType="text"
                                      weight="ultraBold"
                                      text={`Activity: ${activity.name}`}
                                      size="m"
                                    />
                                    <CustomText
                                      useTranslationText={false}
                                      textType="text"
                                      text={activity.description}
                                      size="s"
                                      style={styles.detailText}
                                    />
                                  </View>
                                  <View style={styles.statBox}>
                                    <CustomText
                                      useTranslationText={false}
                                      textType="text"
                                      weight="ultraBold"
                                      text={activity.timeSpent}
                                      size="s"
                                    />
                                    <CustomText
                                      useTranslationText={false}
                                      textType="text"
                                      text="time spent"
                                      size="xs"
                                      style={styles.statLabel}
                                    />
                                  </View>
                                </View>
                              ))}
                          </View>
                        </View>
                      </View>
                    )}
                  </View>
                ))}
              </View>
            )}
          </View>
        ))}
      </MotiView>
    </NoChatBarLayout>
  );
};
export default InsightsView;

const styles = StyleSheet.create({
  classListContainer: {
    flexDirection: "column",
  },
  classContainer: {
    flexDirection: "column",
    backgroundColor: "#FFFFFF",
    borderWidth: 1,
    padding: 20,
    borderColor: "#EAECF0",
    marginBottom: 20,
    borderRadius: 20,
    // alignItems: "center",
    justifyContent: "space-between",
  },
  classHeader: {
    flexDirection: "row",
    // alignItems: "center",
    justifyContent: "space-between",
    flex: 1,
  },
  container: {
    flexDirection: "row",
    flexWrap: "wrap",
    justifyContent: "space-between",
  },
  iconContainer: {
    width: 57.573,
    height: 57.573,
    borderRadius: 57.573 / 2,
    backgroundColor: "#66C61C",
    justifyContent: "center",
    alignItems: "center",
    marginBottom: 12,
  },
  card: {
    display: "flex",
    flexDirection: "column",
    alignItems: "flex-start",
    padding: 20,
    // gap: 20,
    flex: 1,
    borderRadius: 20,
    borderWidth: 1,
    borderColor: "#EAECF0",
    backgroundColor: "#FFF",
    minWidth: 250,
    maxWidth: "33%",
  },
  title: {
    marginTop: 12,
    // fontWeight: "bold",
  },
  text: {
    color: "#667085",
    flexWrap: "wrap",
    maxWidth: "100%",
  },
  description: {
    color: "#667085",
    flexWrap: "wrap",
    maxWidth: "100%",
    marginBottom: 20,
    marginTop: 20,
  },
  highlightsContainer: {
    width: "100%",
    gap: 8,
  },
  highlightBox: {
    backgroundColor: "#F2F4F7",
    padding: 10,
    borderRadius: 8,
  },
  boldText: {
    fontWeight: "bold",
    color: "#333",
  },
  moduleList: {
    flexDirection: "column",
    marginTop: 10,
  },
  moduleContainer: {
    flexDirection: "column",
    backgroundColor: "#FFFFFF",
    borderTopWidth: 1,
    borderColor: "#EAECF0",
    // paddingVertical: 20,
    // paddingHorizontal: 15,
    // marginBottom: 10,
  },
  moduleHeader: {
    flexDirection: "row",
    justifyContent: "space-between",
    // alignItems: "center",
    padding: 20,
  },
  statsContainer: {
    flexDirection: "row",
    gap: 20,
  },
  statBox: {
    flexDirection: "column",
    flexWrap: "wrap",
    justifyContent: "space-between",
    alignItems: "center",
  },
  statLabel: {
    textTransform: "uppercase",
    color: "#667085",
  },
  detailsContainer: {
    flexDirection: "column",
    gap: 20,
    padding: 20,
    // backgroundColor: "#F9FAFB",
    borderRadius: 10,
  },
  detailColumns: {
    flexDirection: "row",
    gap: 20,
  },
  detailBox: {
    flex: 1,
    flexDirection: "column",
  },
  greenBackground: {
    backgroundColor: "#F3FEE7",
    padding: 10,
    borderRadius: 8,
  },
  redBackground: {
    backgroundColor: "#F2F9FF",
    padding: 10,
    borderRadius: 8,
  },
  detailText: {
    color: "#667085",
    marginTop: 5,
  },
  activityRow: {
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "center",
    borderTopWidth: 1,
    borderColor: "#EAECF0",
    paddingTop: 10,
    marginTop: 10,
  },
  activityTextContainer: {
    flexDirection: "column",
    flexShrink: 1,
  },
});
