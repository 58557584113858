import React, { useState } from "react";
import { View, TextInput, StyleSheet, Pressable } from "react-native";
import {
  SubmitIcon,
  WaveForm,
  WaveFormClose,
  AudioOn,
  AudioOff,
  ExpandChatIcon,
  DocumentAttachIcon,
} from "../../svgIcons";
import CustomButton from "../../common/general/CustomButton/CustomButton";
import QuickOptionListContainer from "../../../containers/chat/QuickOptionListContainer/QuickOptionListContainer";
import useResponsiveScreen from "../../../hooks/useResponsiveScreen";
import { useAccessibilityContext } from "../../../contexts/AccessibilityContext";
import { shadowStyles } from "../../../styles/shadow";
import Tooltip from "../../common/tooltip/Tooltip";
import Attachment from "../../chat/Attachment/Attachment";

function ChatBar({
  value,
  isRecording,
  isAudioOn,
  placeholder,
  inputRef,
  onChangeText,
  onSubmitEditing,
  onAudioOffPress,
  onAudioOnPress,
  onRecordingStopPress,
  onRecordingStartPress,
  onToggleChatPress,
  onQuickOptionPress,
  onAttachmentPress,
  onDeleteAttachmentPress,
  showQuickOptions,
  showChatWindow,
  showChat,
  isCustomOffering,
  onGetSuggestionsPress,
  isSubmitEnabled,
  isAttachmentEnabled,
  attachment,
  isAttachmentLoading,
}) {
  const { isMedium } = useResponsiveScreen();
  const { screenReaderIsEnabled } = useAccessibilityContext();
  const INITIAL_HEIGHT = isMedium ? 48 : 36;
  const [inputHeight, setInputHeight] = useState(INITIAL_HEIGHT);

  const borderRadius = inputHeight < 50 && !attachment ? 100 : 25;

  const handleContentSizeChange = (event) => {
    const newHeight = event.nativeEvent.contentSize.height;
    if (newHeight > 240) {
      return;
    }
    setInputHeight(newHeight > INITIAL_HEIGHT ? newHeight : INITIAL_HEIGHT);
  };

  const resetHeight = () => {
    setInputHeight(INITIAL_HEIGHT);
  };

  const handleChangeText = (text) => {
    if (text.length === 0) {
      resetHeight();
    }
    onChangeText(text);
  };

  const handleKeyPress = (e) => {
    if (screenReaderIsEnabled) {
      return;
    }
    if (e.nativeEvent.key === "Enter" && !e.nativeEvent.shiftKey) {
      e.preventDefault();
      onSubmitEditing && onSubmitEditing();
      resetHeight();
    }
  };

  return (
    <View
      style={[
        isMedium ? styles.barWrapperMedium : styles.mobileBarWrapper,
        styles.barWrapper,
        shadowStyles.allAroundShadow,
      ]}
      aria-label="Chat bar">
      {showQuickOptions && isMedium && (
        <QuickOptionListContainer
          onQuickOptionPress={onQuickOptionPress}
          isCustomOffering={isCustomOffering}
          onGetSuggestionsPress={onGetSuggestionsPress}
        />
      )}
      <View style={[styles.chatWrapper, isMedium ? { gap: 24 } : { gap: 4 }]}>
        {showChatWindow && (
          <Tooltip content={showChat ? "Close chat" : "Open chat"} offset={-20} horizontalOffset={-35}>
            <Pressable
              role="button"
              aria-label="Expand chat"
              accessibilityHint="Press to display chat window"
              style={[
                styles.expandChatButton,
                isMedium
                  ? { height: 48, width: 48 }
                  : {
                      height: 36,
                      width: 36,
                    },
              ]}
              onPress={onToggleChatPress}>
              <ExpandChatIcon />
            </Pressable>
          </Tooltip>
        )}
        <View style={[styles.container, { borderRadius }, isRecording ? styles.recording : null]}>
          {attachment && (
            <View style={styles.attachmentContainer}>
              <Attachment
                title={attachment.title}
                fileType={attachment.fileType}
                isLoading={isAttachmentLoading}
                showDeleteButton={true}
                onDeletePress={onDeleteAttachmentPress}
              />
            </View>
          )}
          <View style={[styles.inputContainer, { height: Math.min(inputHeight, 240) }]}>
            {isAttachmentEnabled && (
              <Tooltip content="Attach PDF File" offset={-28} horizontalOffset={-52}>
                <CustomButton
                  text=""
                  rightIcon={null}
                  textStyle={{}}
                  disabled={false}
                  leftIcon={<DocumentAttachIcon />}
                  styleType="transparent"
                  style={styles.attachButton}
                  onPress={onAttachmentPress}
                  textProps={{}}
                  aria-label="Stop recording"
                />
              </Tooltip>
            )}
            <TextInput
              id="chatInput"
              ref={inputRef}
              value={value}
              maxLength={7500}
              multiline
              numberOfLines={7}
              placeholder={placeholder}
              placeholderTextColor="#808080"
              style={[
                styles.textInput,
                isMedium ? styles.textInputMedium : styles.textInputMobile,
                { height: inputHeight },
              ]}
              onChangeText={handleChangeText}
              onSubmitEditing={onSubmitEditing}
              onKeyPress={handleKeyPress}
              onContentSizeChange={handleContentSizeChange}
              scrollEnabled={true}
              aria-label="Chat text input"
            />

            <View style={styles.buttons}>
              {value !== "" ? (
                <CustomButton
                  text=""
                  rightIcon={null}
                  textStyle={{}}
                  disabled={!isSubmitEnabled}
                  leftIcon={<SubmitIcon />}
                  styleType="transparent"
                  style={styles.button}
                  onPress={onSubmitEditing}
                  textProps={{}}
                  aria-label="Submit chat"
                />
              ) : isRecording ? (
                <Tooltip content="Send voice input" offset={5} horizontalOffset={-55}>
                  <CustomButton
                    text=""
                    rightIcon={null}
                    textStyle={{}}
                    disabled={false}
                    leftIcon={<WaveFormClose />}
                    styleType="transparent"
                    style={styles.stopButton}
                    onPress={onRecordingStopPress}
                    textProps={{}}
                    aria-label="Stop recording"
                  />
                </Tooltip>
              ) : (
                <Tooltip content="Start voice input" offset={5} horizontalOffset={-55}>
                  <CustomButton
                    text=""
                    rightIcon={null}
                    textStyle={{}}
                    disabled={false}
                    leftIcon={<WaveForm />}
                    styleType="transparent"
                    style={styles.button}
                    onPress={onRecordingStartPress}
                    textProps={{}}
                    aria-label="Start recording"
                  />
                </Tooltip>
              )}
              {isAudioOn ? (
                <Tooltip content="Mute Voice" offset={-30} horizontalOffset={-40}>
                  <CustomButton
                    leftIcon={<AudioOn />}
                    styleType="transparent"
                    style={styles.button}
                    onPress={onAudioOffPress}
                    text=""
                    rightIcon={null}
                    textStyle={{}}
                    disabled={false}
                    textProps={{}}
                    aria-label="Turn off audio"
                  />
                </Tooltip>
              ) : (
                <Tooltip content="Unmute Voice" offset={-30} horizontalOffset={-40}>
                  <CustomButton
                    leftIcon={<AudioOff />}
                    styleType="transparent"
                    style={styles.button}
                    onPress={onAudioOnPress}
                    text=""
                    rightIcon={null}
                    textStyle={{}}
                    disabled={false}
                    textProps={{}}
                    aria-label="Turn on audio"
                  />
                </Tooltip>
              )}
            </View>
          </View>
        </View>
      </View>
    </View>
  );
}

const styles = StyleSheet.create({
  barWrapper: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    zIndex: 999,
    width: "100%",
    maxWidth: 1400,
  },
  barWrapperMedium: {
    borderTopLeftRadius: 40,
    borderTopRightRadius: 40,
    paddingVertical: 20,
    paddingHorizontal: 40,
    gap: 16,
  },
  mobileBarWrapper: {
    borderTopLeftRadius: 20,
    borderTopRightRadius: 20,
    paddingVertical: 10,
    paddingHorizontal: 10,
    gap: 10,
  },
  chatWrapper: {
    backgroundColor: "#fff",
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "space-between",
    width: "100%",
  },
  expandChatButton: {
    backgroundColor: "#F2F4F7",
    borderRadius: 100,
    justifyContent: "center",
    alignItems: "center",
  },
  container: {
    position: "relative",
    display: "flex",
    flexGrow: 1,
    backgroundColor: "#F2F4F7",
    elevation: 5,
  },
  inputContainer: {
    position: "relative",
    display: "flex",
    flexGrow: 1,
    flexDirection: "row",
    alignItems: "center",
    elevation: 5,
    justifyContent: "space-between",
  },
  attachmentContainer: {
    paddingLeft: 8,
    paddingTop: 8,
  },
  textInput: {
    flex: 1,
    fontWeight: "400",
    fontFamily: "Inter",
    color: "#808080",
    outlineStyle: "none",
    textAlignVertical: "top",
    maxHeight: 500,
  },
  textInputMedium: {
    paddingHorizontal: 10,
    paddingVertical: 12,
    fontSize: 16,
  },
  textInputMobile: {
    paddingHorizontal: 16,
    paddingVertical: 8,
    fontSize: 14,
  },
  recording: {
    borderColor: "#3EFE5D",
    borderWidth: 4,
  },
  buttons: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    gap: 10,
  },
  button: {
    padding: 0,
  },
  stopButton: {
    padding: 0,
    bottom: -2,
  },
  attachButton: {
    justifyContent: "center",
    alignItems: "center",
    paddingRight: 0,
  },
});

export default ChatBar;
