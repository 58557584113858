import React, { useEffect } from "react";
import { View, StyleSheet, Pressable } from "react-native";
import CustomText from "../components/common/general/CustomText/CustomText";
import { useRoute, useNavigation } from "@react-navigation/native";
import { RouteProp } from "@react-navigation/native";
import { RootStackParamList } from "../navigation/AppNavigator.web";
import { NativeStackNavigationProp } from "@react-navigation/native-stack";
import { ExitDoor } from "../components/svgs/common";
import CustomButton from "../components/common/general/CustomButton/CustomButton";
import CustomSpacing from "../components/common/layout/CustomSpacing/CustomSpacing";
import RoundedLabel from "../components/common/label/RoundedLabel/RoundedLabel";
import ChatMenuContainer from "../containers/chatmenu/ChatMenuContainer/ChatMenuContainer";
import ChatContainer from "../containers/chat/ChatContainer/ChatContainer";
import ChatBarContainer from "../containers/navigation/ChatBarContainer/ChatBarContainer";
import {
  useGetAiTutorQuery,
  useGetChatByObjectIdQuery,
  useGetChatsQuery,
  useGetCourseByIdQuery,
} from "../graphql/generated/graphql";
import useResponsiveScreen from "../hooks/useResponsiveScreen";
import { Context, useChatBar, ConversationType } from "../contexts/ChatBarContext";
import { useAppState, actions } from "../contexts/AppStateContext";
import Loader from "../components/common/status/Loader/Loader";
import AsyncStorage from "@react-native-async-storage/async-storage";
import useWebSocket from "../hooks/useWebSocket";

const CustomCourseAITutorChatView = () => {
  const route = useRoute<RouteProp<RootStackParamList, "Custom Course AITutor">>();
  const navigation = useNavigation<NativeStackNavigationProp<RootStackParamList>>();
  const { resource_id, course_id, integration_id, resource_type } = route.params;
  const { isMedium } = useResponsiveScreen();
  const { sendMessage } = useWebSocket();
  const {
    setContext,
    setActiveContextAndObjectId,
    setConversationType,
    setSchoolMessage,
    mapRouteToContext,
    setMetaIsLoading,
    state: chatBarState,
  } = useChatBar();
  const { data: chatsData, refetch: refetchChatsData } = useGetChatsQuery();
  const { state: appState, dispatch } = useAppState();
  const { data: courseData } = useGetCourseByIdQuery({
    variables: {
      id: course_id,
    },
  });

  const {
    data: chatByObjectIdData,
    loading: chatByObjectIdLoading,
    error: chatByObjectIdError,
    refetch: chatByObjectIdRefetch,
  } = useGetChatByObjectIdQuery({
    variables: {
      objectId: resource_id,
      context: Context.AITUTOR,
    },
    skip: !resource_id,
  });

  useEffect(() => {
    if (chatBarState.activeObjectId) {
      chatByObjectIdRefetch({
        objectId: chatBarState.activeObjectId,
        context: chatBarState.activeContext,
      });
    }
  }, [appState.meta.chatHistory]);

  const handleGetHelpFromAxio = async (id: string) => {
    const token = await AsyncStorage.getItem("token");

    sendMessage({
      meta: {
        token: token,
        abort: false,
        timeZone: Intl.DateTimeFormat().resolvedOptions().timeZone,
        isLoading: true,
        displayMessage: false,
      },
      state: {
        general: "SCHOOL",
        context: mapRouteToContext("Custom Resource Student"),
        params: {
          resource_type: "AITutor",
          resource_id: id,
          course_id,
          integration_id,
        },
        school: {
          contentType: "AITutor",
          contentId: id,
          classId: course_id,
          integrationId: integration_id,
        },
      },
      content: {
        message: chatByObjectIdData?.getChatByObjectId?.id
          ? "Let’s continue where we left off"
          : "Please begin by introducing me to this topic",
        author: "HUMAN",
        type: null,
      },
    });
    setMetaIsLoading(true);
  };

  useEffect(() => {
    if (chatByObjectIdLoading) {
      return;
    }

    if (!chatByObjectIdData?.getChatByObjectId?.id) {
      handleGetHelpFromAxio(resource_id);
    }
  }, [chatByObjectIdData, chatByObjectIdLoading]);

  useEffect(() => {
    setConversationType(ConversationType.SCHOOL);
    setActiveContextAndObjectId({
      context: Context.AITUTOR,
      objectId: resource_id,
    });
    setContext({
      context: Context.AITUTOR,
      params: {
        resource_id: resource_id,
        course_id,
        integration_id,
        resource_type: "AITutor",
      },
    });
    setSchoolMessage({
      contentType: resource_type,
      contentId: resource_id,
      classId: course_id,
      integrationId: integration_id,
    });
  }, [resource_id, course_id, integration_id, resource_type]);

  const handleExitPress = () => {
    navigation.navigate("Custom Course Student", {
      resource_type,
      resource_id,
      course_id,
      integration_id,
    });
  };

  const handleChatPress = async (chat) => {
    dispatch({
      type: actions.SET_CONTENT,
      payload: { message: "" },
    });

    setConversationType(ConversationType.SCHOOL);

    const { resource_id, course_id, integration_id, resource_type } = chat.params;

    setSchoolMessage({
      contentType: resource_type,
      contentId: resource_id,
      classId: course_id,
      integrationId: integration_id,
    });

    setContext({
      context: Context.AITUTOR,
      params: chat.params as { [key: string]: string },
    });

    setActiveContextAndObjectId({
      context: Context.AITUTOR,
      objectId: chat.objectId,
    });
  };

  if (chatByObjectIdLoading) {
    return <Loader />;
  }

  return (
    <View style={styles.container}>
      {/* Header */}
      <View style={styles.header}>
        <View style={styles.headerLeft}>
          <View style={styles.exitButtonContainer}>
            <CustomButton
              leftIcon={<ExitDoor />}
              onPress={handleExitPress}
              size="s"
              styleType="transparent"
              text=""
              rightIcon={null}
              style={{}}
              textStyle={{}}
              disabled={false}
              textProps={{}}
            />
          </View>
          <CustomSpacing type="horizontal" size="m" />
          <CustomText
            text={courseData?.getCourseById?.data?.[0]?.name}
            useTranslationText={false}
            weight="bold"
            size="m"
          />
        </View>
      </View>

      {/* Main Content */}
      <View style={styles.content}>
        {/* Chat Menu */}
        <View style={styles.menu}>
          <ChatMenuContainer chatsData={chatsData} onChatPress={handleChatPress} onMobileChatPress={() => {}} />
        </View>

        {/* Chat Area */}
        <View style={styles.chatArea}>
          <ChatContainer chatData={chatByObjectIdData?.getChatByObjectId} />
          <View style={styles.bar}>
            <ChatBarContainer />
          </View>
        </View>
      </View>
    </View>
  );
};

const styles = StyleSheet.create({
  container: {
    flex: 1,
    backgroundColor: "#fff",
  },
  header: {
    flexDirection: "row",
    justifyContent: "space-between",
    borderBottomColor: "#D0D5DD",
    borderBottomWidth: 1,
  },
  headerLeft: {
    paddingVertical: 10,
    flexDirection: "row",
    alignItems: "center",
  },
  exitButtonContainer: {
    borderRightColor: "#D0D5DD",
    borderRightWidth: 1,
    paddingHorizontal: 10,
  },
  content: {
    flex: 1,
    flexDirection: "row",
  },
  menu: {
    width: 270,
  },
  chatArea: {
    flex: 1,
    position: "relative",
  },
  bar: {
    width: "100%",
    display: "flex",
    justifyContent: "center",
    alignSelf: "center",
    flexDirection: "row",
  },
});

export default CustomCourseAITutorChatView;
